import {
  IS_CONFIG_LOADING,
  CONFIG_LOADING_COMPLETE,
  GET_DASHBOARD_SUMMARY_SUCCESS,
  GET_DASHBOARD_SUMMARY_FAILED,
  GET_WARDS_SUCCESS,
  GET_WARDS_FAILED,
  GET_DASHBOARD_FILTERS_SUCCESS,
  GET_DASHBOARD_FILTERS_FAILED,
  GET_MEMBER_DETAILS_SUCCESS,
  GET_MEMBER_DETAILS_SUCCESS_INIT_ACTION,
  GET_MEMBER_DETAILS_FAILED,
  GET_JOB_FILTER_SUCCESS,
  GET_JOB_FILTER_FAILED,
  GET_EDUCATION_FILTER_SUCCESS,
  GET_EDUCATION_FILTER_FAILED,
  MEMBER_DETAILS_DOWNLOAD_INIT_ACTION,
  MEMBER_DETAILS_DOWNLOAD_INIT_ACTION_SUCEESS,
  MEMBER_DETAILS_DOWNLOAD_INIT_ACTION_FAIL,
  GET_ANALISIS_FILTER_FAILED,
  GET_ANALISIS_FILTER_SUCCESS,
  ACCESSIBLE_LAYER_REQUEST
  
} from "./constants";

import { loadingActions } from "../../loader/data/action";
import DashboardService from "../../../service/DashboardService";
import { snackbarActions } from "../../snackbar/data/action";
import LayerService from "../../../service/LayerService";

export function isConfigloading() {
  return { type: IS_CONFIG_LOADING };
}

export function configLoadingComplete() {
  return { type: CONFIG_LOADING_COMPLETE };
}

export const getDashboardSummary = (localBodyId, wardId) => {
  return (dispatch) => {
    dispatch(loadingActions.isloading());
    DashboardService.getDashboardSummary(localBodyId, wardId).then(
      (response) => {
        if (response.data) {
          dispatch(success(response));
          dispatch(loadingActions.loadingComplete());
        } else {
          dispatch(failure(response));
          dispatch(loadingActions.loadingComplete());
        }
      }
    );

    function success(response) {
      return { type: GET_DASHBOARD_SUMMARY_SUCCESS, response };
    }
    function failure(error) {
      return { type: GET_DASHBOARD_SUMMARY_FAILED, error };
    }
  };
};

export const getWards = (localBodyId) => {
  return (dispatch) => {
    dispatch(loadingActions.isloading());
    DashboardService.getWards(localBodyId).then((response) => {
      if (response.data) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
      }
      dispatch(loadingActions.loadingComplete());
    });

    function success(response) {
      return { type: GET_WARDS_SUCCESS, response };
    }
    function failure(error) {
      return { type: GET_WARDS_FAILED, error };
    }
  };
};

export const getDashboardFilters = () => {
  return (dispatch) => {
    dispatch(loadingActions.isloading());
    DashboardService.getDashboardFilters().then((response) => {

      if (response.data) {
        dispatch(success(response));
        dispatch(loadingActions.loadingComplete());
      } else {
        dispatch(failure(response));
        dispatch(loadingActions.loadingComplete());
      }
      dispatch(loadingActions.loadingComplete());
    });
    function success(response) {
      return { type: GET_DASHBOARD_FILTERS_SUCCESS, response };
    }
    function failure(error) {
      return { type: GET_DASHBOARD_FILTERS_FAILED, error };
    }
  };
};

export const getMemberDetails = (body) => {
  return (dispatch) => {
    dispatch(loadingActions.isloading());
    dispatch(init());
    DashboardService.getMemberDetails(body).then((response) => {
      if (response.data) {
        dispatch(success(response));
        if (response.data.member_details.length === 0) {
          dispatch(snackbarActions.showSnackbar("No data found"));
        } else{
          dispatch(snackbarActions.hideSnackbar());
        }
      } else {
        dispatch(failure(response));
      }
      dispatch(loadingActions.loadingComplete());
    });
    function success(response) {
      return { type: GET_MEMBER_DETAILS_SUCCESS, response };
    }
    function failure(error) {
      return { type: GET_MEMBER_DETAILS_FAILED, error };
    }
    function init() {
      return { type: GET_MEMBER_DETAILS_SUCCESS_INIT_ACTION };
    }
  };
};
export const generateReport = (reportValues) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService.generateReport(reportValues).then((response) => {
      if (response) {
        dispatch(success(response));
        dispatch(loadingActions.loadingComplete());
      } else {
        dispatch(failure(response));
        dispatch(loadingActions.loadingComplete());
      }
    });
    function request() {
      return { type: GENERATE_REPORT };
    }
    function success(response) {
      return { type: GENERATE_REPORT_SUCCESS, response };
    }
    function failure(error) {
      return { type: GENERATE_REPORT_FAILED, error };
    }
  };
};

export const getJobFilter = (category_id) => {
  return (dispatch) => {
    dispatch(loadingActions.isloading());
    DashboardService.getJobFilter(category_id).then((response) => {
      if (response.data) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
      }
      dispatch(loadingActions.loadingComplete());
    });
    function success(response) {
      return { type: GET_JOB_FILTER_SUCCESS, response };
    }
    function failure(error) {
      return { type: GET_JOB_FILTER_FAILED, error };
    }
  };
};

export const getEducationFilter = (category_id) => {
  return (dispatch) => {
    dispatch(loadingActions.isloading());
    DashboardService.getEducationFilter(category_id).then((response) => {
      if (response.data) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
      }
      dispatch(loadingActions.loadingComplete());
    });
    function success(response) {
      return { type: GET_EDUCATION_FILTER_SUCCESS, response };
    }
    function failure(error) {
      return { type: GET_EDUCATION_FILTER_FAILED, error };
    }
  };
};

export const downloadAction = (data) => {
  return (dispatch) => {
    dispatch(request(data));
    dispatch(loadingActions.isloading());
    DashboardService.downloadMemberDetails(data).then((response) => {
      if (response.data) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
      }
      dispatch(loadingActions.loadingComplete());
    });

    function request(reportValues) {
      return { type: MEMBER_DETAILS_DOWNLOAD_INIT_ACTION, payload: reportValues };
    }

    function success(response) {
      return { type: MEMBER_DETAILS_DOWNLOAD_INIT_ACTION_SUCEESS, response };
    }
    function failure(error) {
      return { type: MEMBER_DETAILS_DOWNLOAD_INIT_ACTION_FAIL, error };
    }
  };
};
export const getAnalysisFilter = (layerlist, geometry, featureGeometry) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    LayerService.accessibleLayers( layerlist, geometry, featureGeometry ).then((response) => {
      if (response) {
        dispatch(success(response));
        dispatch(loadingActions.loadingComplete());
      } else {
        dispatch(failure(response));
        dispatch(loadingActions.loadingComplete());
      }
    });
    function request() {
      return { type: ACCESSIBLE_LAYER_REQUEST };
    }
   
    function success(response) {
      return { type: GET_ANALISIS_FILTER_SUCCESS , response };
    }
    function failure(error) {
      return { type: GET_ANALISIS_FILTER_FAILED, error };
    }
  };
};
