import React, { Component } from "react";
import TableFormat from "./TableFormat";
import DataFormat from "../../helper/DataFormat";

const tableData = (key, obj) => {
  return (
    <>
      <thead>
        <tr>
          <td colSpan="2" style={{ textAlign: "center" }}>
            <b> {key}</b>
          </td>
        </tr>
      </thead>
      <tbody className="tablebody">
        {obj[key].map((obj) => {
          if (DataFormat.dataFormatOr(obj)) return;
          return (
            <tr>
              <td colSpan="2">
                <ul>
                  <li>{DataFormat.dataFormat(obj)}</li>
                </ul>
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};
class MemberDetails extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { data } = this.props;
    const styleObj = {
      width: 400,
      textAlign: "center",
    };
    return (
      <TableFormat displayName={"Member Details"}>
        {data.map((obj, key) => {
          return (
            <>
              <thead>
                <tr key={key}>
                  <th colSpan="2" style={styleObj} bgcolor="#d1473d">
                    {obj["First Name"] === "None" ? "" : obj["First Name"]}
                    &nbsp;&nbsp;
                    {obj["Last Name"] === "None" ? "" : obj["Last Name"]}
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(obj).map((value) => {
                  return (
                    <>
                      {!(
                        DataFormat.dataFormatOr(obj[value]) ||
                        obj[value] === "None" ||
                        value === "First Name" ||
                        value === "Last Name" ||
                        value === "Disability" ||
                        value === "Diseases" ||
                        value === "Pension Type"
                      ) && (
                          value === "First Name" ||
                          value === "Last Name" ||
                          value === "gender" ||
                          value === "Age" ||
                          value === "Is Month" ||
                          value === "Education" ||
                          value === "Category of Education" ||
                          value === "Marital Status" ||
                          value === "Member Occupation" ||
                          value === "Blood Group" ||
                          value === "NRI or NRK" ||
                          value === "Diseases" ||
                          value === "Disability" ||
                          value === "Pension Type" ||
                          value === "Occupation Loss" ||
                          value === "Palliative Care" ||
                          value === "Religion" ||
                          value === "Drunken" ||
                          value === "Insulin" ||
                          value === "Smoking" 
                        ) && (
                          <tr>
                            <td style={{ width: "300px" }}>{value}</td>
                            <td
                              style={{
                                width: "300px",
                                overflowWrap: "anywhere",
                              }}
                            >
                              {DataFormat.dataFormat(obj[value])}
                            </td>
                          </tr>
                        )}
                    </>
                  );
                })}
              </tbody>
              {obj.hasOwnProperty("Diseases") &&
                obj["Diseases"][0] &&
                tableData("Diseases", obj)}
              {obj.hasOwnProperty("Pension Type") &&
                obj["Pension Type"][0] &&
                tableData("Pension Type", obj)}
              {obj.hasOwnProperty("Disability") &&
                obj["Disability"][0] &&
                tableData("Disability", obj)}
            </>
          );
        })}
      </TableFormat>
    );
  }
}
export default MemberDetails;
