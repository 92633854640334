import React, { Component } from "react";
import { transform } from "ol/proj.js";
// import tokml from "tokml";
// import { saveAs } from "file-saver";
import { connect } from "react-redux";
import {
  OverlayTrigger,
  ButtonToolbar,
  Modal,
  Panel,
  Button,
  Tooltip,
} from "react-bootstrap";
import { Polygon } from "ol/geom.js";
import Draw from "ol/interaction/Draw";
import View from "ol/View";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer.js";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";
import { GeoJSON } from "ol/format.js";
import { unByKey } from "ol/Observable.js";
import getLayerTree from "../../../map/helper/LayerTreeLoader";
import { snackbarActions } from "../../../../../components/snackbar/data/action";
import styleFunction from "../../../map/helper/vectorStyle";
import {
  mapQueryPolygon,
  requestLayer,
  updateSearchResultLayer,
} from "./data/action";
import selectRed from "../../../../../asset/svg/polygon_red.svg";
import ResultModel from "./components/ResultModel";
import Widget from "../../../../../containers/widget/widget";
import "./SelectByPolygon.css";
import SaveModel from "./components/saveModel";
import PolygonTable from "./components/polygonTable";
import MapSearchService from "../../../../../service/MapSearchService";
import compass from "../../../../../asset/png/compass.png";
import ulccs_emblem from "../../../../../asset/png/ULCCS emblem.png";
import emblem from "../../../../../asset/png/emblem.png";
import LocalStorageHandler from "../../../../../util/storage";
import jsPDF from "jspdf";
import { toStringHDMS } from "ol/coordinate";
import Select from "ol/interaction/Select";
import Modify from "ol/interaction/Modify";

class SelectByPolygon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawType: null,
      vectorSource: null,
      draw: null,
      sketch: null,
      listener: null,
      layerlist: [],
      geometry: null,
      show: false,
      result: null,
      showResult: false,
      resultVectorSource: null,
      enabled: false,
      polygonName: null,
      showTable: false,
      showSave: false,
      resultLayer: null,
      response_id: null,
      pageSize: "a3",
      resolution: "72",
      orientation: "landscape",
      layout: "L1",
      polygons: [],
      select: null,
      modify: null,
    };
  }

  componentDidMount() {
    this.setState({
      vectorSource: new VectorSource(),
      resultVectorSource: new VectorSource(),
    });
  }

  handleDrawConrtol = () => {
    const { mapComponent } = this.props;
    const { vectorSource, draw, resultVectorSource } = this.state;
    const enabled = this.state.enabled;
    // if (enabled) {
    //   vectorSource.clear();
    //   resultVectorSource.clear();
    //   this.setState({ enabled: false });
    //   return;
    // }

    // mapComponent.removeInteraction(draw);
    // vectorSource.clear();
    // resultVectorSource.clear();
    mapComponent.addLayer(this.getVectorLayer());

    let listener;
    this.setState(
      {
        output: 0.0,
        draw: this.addInteraction(),
        enabled: true,
        modify: this.addModify(),
      },
      () => {
        mapComponent.addInteraction(this.state.draw);
        const { draw } = this.state;

        draw.on("drawstart", (evt) => {
          listener = this.handleDrawStart(evt);
        });
        draw.on("drawend", (listener) => {
          this.handleDrawEnd(listener);
        });
      }
    );
  };

  handleDrawStart = (evt) => {
    this.setState({
      sketch: evt.feature,
    });
    this.props.mapComponent.removeInteraction(this.state.modify);
    return this.state.sketch.getGeometry().on("change", (evt) => {
      let geom = evt.target;
      if (geom instanceof Polygon) {
        // this.setState({
        //   output: this.formatArea(geom),
        // });
      }
    });
  };

  handleDrawEnd = (listener) => {
    const { vectorSource } = this.state;
    this.setState({
      sketch: null,
    });
    const polygonID = Date.now().toString();
    var feature = listener.feature;

    var coord = feature
      .getGeometry()
      .clone()
      .transform("EPSG:3857", "EPSG:4326");
    unByKey(listener);
    var res = [];
    var layerdetails = {};
    this.props.mapComponent.removeInteraction(this.state.draw);
    this.props.mapComponent.removeInteraction(this.state.modify);
    var layers = this.props.mapComponent.values_.layergroup.values_.layers
      .array_[1].values_.layers.array_;
    layers.forEach((element) => {
      if (element.values_.visible) {
        var obj = {};
        let layerTree = getLayerTree();
        layerTree.map((item) => {
          if (item.hasOwnProperty("children")) {
            let res = item.children.filter((layer) => {
              if (layer.label == element.values_.name) {
                obj["model_name"] = layer.model_name;
                obj["field"] = layer.category_field;
                obj["field_value"] = layer.category_id;
              }
            });
          }
        });
        obj["layer_name"] = element.values_.name;
        obj["service_name"] = element.values_.source.params_.LAYERS;
        res.push(obj);
      }
    });
    if (res.length > 0) {
      layerdetails["layer"] = res;
      layerdetails["flatCoordinates"] = coord.flatCoordinates;
      feature.setId(polygonID);
      vectorSource.addFeature(feature);
      this.setState((prevState) => ({
        polygons: [...prevState.polygons, feature],
      }));
      this.setState(
        {
          layerlist: layerdetails,
          geometry: coord,
        },
        () => {
          this.handleShowSave();
        }
      );
    } else {
      this.props.showSnackbar("Select Required Layers Before Drawing");
    }
  };

  savePolygon = () => {
    const { vectorSource } = this.state;
    const polygonName = this.state.polygonName;
    const polygonID = this.state.polygons[
      this.state.polygons.length - 1
    ].getId(); // Get the ID of the last drawn polygon

    // console.log("state values",st);

    let data = [];
    const value = {
      [polygonName]: {
        layer: this.state.layerlist,
        geometry: this.state.geometry,
        polygonID: polygonID, // Store the polygon ID
      },
    };
    let combinedDetails = JSON.parse(localStorage.getItem("Polygonname"));
    if (combinedDetails) {
      combinedDetails.push(value);
      localStorage.setItem(`Polygonname`, JSON.stringify(combinedDetails));
      MapSearchService.mapQueryPolygon(
        this.state.layerlist,
        this.state.geometry
      ).then((res) => {
        if (res.data.length > 0) {
          this.addSearchResultToMap(res.data);
          this.setState(
            {
              result: res.data,
              showTable: true,
            },
            () => {
              this.handleShowResult();
            }
          );
        }
      });
      this.handleShowSave();
    } else {
      data.push(value);
      localStorage.setItem(`Polygonname`, JSON.stringify(data));
      MapSearchService.mapQueryPolygon(
        this.state.layerlist,
        this.state.geometry
      ).then((res) => {
        if (res.data.length > 0) {
          this.addSearchResultToMap(res.data);
          this.setState(
            {
              result: res.data,
              showTable: true,
            },
            () => {
              this.handleShowResult();
            }
          );
        }
      });
      this.handleShowSave();
    }
  };

  handleModify = () => {
    const { select, modify, vectorSource } = this.state;

    if (select && modify) {
      this.props.mapComponent.removeInteraction(select);
      this.props.mapComponent.removeInteraction(modify);
      this.setState({ select: null, modify: null });
    } else {
      const select = new Select();
      const modify = new Modify({
        source: vectorSource,
      });

      let layerList = {};

      modify.on("modifyend", (event) => {
        event.features.forEach((feature) => {
          // console.log("featrues data",feature);
          let coords = feature
            .getGeometry()
            .clone()
            .transform("EPSG:3857", "EPSG:4326");

          const layerlistValue = this.state.layerlist.layer;
          layerList["layer"] = layerlistValue;
          layerList["flatCoordinates"] = coords.flatCoordinates;

          this.setState(
            {
              layerlist: layerList,
              geometry: coords,
            },
            () => {
              MapSearchService.mapQueryPolygon(
                this.state.layerlist,
                this.state.geometry
              ).then((res) => {
                if (res.data.length > 0) {
                  this.addSearchResultToMap(res.data);
                  this.setState(
                    {
                      result: res.data,
                      showTable: true,
                    },
                    () => {
                      this.handleShowResult();
                    }
                  );
                }
              });
            }
          );
        });
      });

      // this.props.mapComponent.addInteraction(select);
      this.props.mapComponent.addInteraction(modify);

      this.setState({ select, modify });
    }
  };

  handleshowPolygon = () => {
    this.setState({
      showTable: !this.state.showTable,
    });
  };

  handleShowResult = () => {
    this.setState({
      // showResult: !this.state.showResult,
      showResult: true,
    });
  };

  handleCloseResult = () => {
    this.setState({
      // showResult: !this.state.showResult,
      showResult: false,
    });
  };

  handleShowSave = () => {
    this.setState({
      showSave: !this.state.showSave,
    });
  };

  handleSaveClose = () => {
    const { vectorSource } = this.state;

    const polygonFeature = this.state.polygons;

    vectorSource.removeFeature(polygonFeature[polygonFeature.length - 1]);

    this.setState({
      showSave: false,
    });
  };

  handleViewsavedPolygon = (name) => {
    const polygonData = JSON.parse(localStorage.getItem("Polygonname")) || [];
    polygonData.map((item) =>
      Object.keys(item).map((key) => {
        if (key == name) {
          const geometry = item[name].geometry;
          const layerlist = item[name].layer;

          let responseValues = [];
          MapSearchService.mapQueryPolygon(layerlist, geometry).then((res) => {
            if (res.data.length > 0) {
              this.addSearchResultToMap(res.data);
              this.setState(
                {
                  result: res.data,
                },
                () => {
                  this.handleShowResult();
                }
              );
            }
            responseValues.push(res.data);
          });
        }
      })
    );
  };

  getInfoAttributes(element) {
    let infoAttributes;
    let layerTree = getLayerTree();
    layerTree.map((item) => {
      if (item.hasOwnProperty("children")) {
        let res = item.children.filter((layer) => {
          if (layer.label == element.layer) {
            infoAttributes = {
              minimalInfoAttributes: layer.minimalInfoAttributes,
              detailedInfoAttributes: layer.detailedInfoAttributes,
            };
          }
        });
      }
    });
    return infoAttributes;
  }

  addSearchResultToMap(response_id) {
    const { mapComponent } = this.props;

    let resultVectorSource = this.state.resultVectorSource;

    response_id.forEach((element) => {
      if (
        element.layer !== "Ward Boundary" &&
        element.layer !== "Local body Boundary"
      ) {
        let infoAttributes = this.getInfoAttributes(element);
        let resultLayer = new VectorLayer({
          name: element.layer,
          infoAttributes: infoAttributes,
          visible: true,
          source: resultVectorSource,
          style: (feature, resolution) => styleFunction(feature, resolution),
        });

        let features = new GeoJSON().readFeatures(element.result);
        // let featureId = features.get
        resultVectorSource.addFeatures(features);
        mapComponent.addLayer(resultLayer);
        this.setState({
          resultLayer: resultLayer,
        });
      }
    });

    mapComponent.getView().fit(resultVectorSource.getExtent());
  }

  getVectorLayer() {
    return new VectorLayer({
      source: this.state.vectorSource,
      projection: "EPSG:4326",
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "red",
          width: 2,
        }),
      }),
    });
  }

  addInteraction() {
    return new Draw({
      source: this.state.vectorSource,
      type: "Polygon",
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.5)",
          lineDash: [10, 10],
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: "rgba(0, 0, 0, 0.7)",
          }),
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
        }),
      }),
    });
    // return draw;
  }

  addModify = () => {
    return new Modify({
      source: this.state.vectorSource,
      type: "Polygon",
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.5)",
          lineDash: [10, 10],
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: "rgba(0, 0, 0, 0.7)",
          }),
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
        }),
      }),
    });
  };

  handlePolygonName = (e) => {
    // e.preventdefault()
    // console.log("name",e.target.value);
    this.setState({
      polygonName: e.target.value,
    });
  };

  // savePolygon = () => {

  //   const {vectorSource} =this.state
  //   const polygonName= this.state.polygonName;

  //   let data = [];
  //   const value ={
  //     [polygonName]:{
  //       layer: this.state.layerlist,
  //       geometry: this.state.geometry,
  //     }
  //   }
  //   let combinedDetails = JSON.parse(localStorage.getItem("Polygonname"));
  //   if (combinedDetails) {
  //     combinedDetails.push(value)
  //     localStorage.setItem(`Polygonname`, JSON.stringify(combinedDetails));
  //      MapSearchService.mapQueryPolygon(this.state.layerlist, this.state.geometry).then((res) => {
  //           if(res.data.length>0){
  //             this.addSearchResultToMap(res.data);
  //             this.setState({
  //               result: res.data,
  //               showTable: true,
  //             },()=>{
  //               this.handleShowResult()
  //             })
  //             // this.handleShowResult()
  //           }
  //           // responseValues.push(res.data)
  //         });
  //     // vectorSource.clear();
  //     this.handleShowSave()
  //     // this.handleshowPolygon()
  //   } else {
  //     data.push(value)
  //     localStorage.setItem(`Polygonname`, JSON.stringify(data));
  //     MapSearchService.mapQueryPolygon(this.state.layerlist, this.state.geometry).then((res) => {
  //       if(res.data.length>0){
  //         this.addSearchResultToMap(res.data);
  //         this.setState({
  //           result: res.data,
  //           showTable: true,
  //         },()=>{
  //           this.handleShowResult()
  //         })
  //         // this.handleShowResult()
  //       }
  //       // responseValues.push(res.data)
  //     });
  //     // vectorSource.clear();
  //     this.handleShowSave()
  //     // this.handleshowPolygon()
  //   }
  // }

  render() {
    return (
      <React.Fragment>
        {this.state.showResult && (
          <ResultModel
            show={this.state.showResult}
            handleShowResult={this.handleShowResult}
            handleCloseResult={this.handleCloseResult}
            result={this.state.result}
          />
        )}
        {this.state.showSave && (
          <SaveModel
            show={this.state.showSave}
            polygonName={this.state.polygonName}
            handlePolygonName={this.handlePolygonName}
            // handleshowPolygon={this.handleshowPolygon}

            handleSaveClose={this.handleSaveClose}
            handleShowSave={this.handleShowSave}
            savePolygon={this.savePolygon}
          />
        )}
        {this.state.showTable && (
          <PolygonTable
            show={this.state.showTable}
            state={this.state}
            result={this.state.result}
            addSearchResultToMap={this.addSearchResultToMap}
            handleViewsavedPolygon={this.handleViewsavedPolygon}
            handleShowResult={this.handleShowResult}
            getInfoAttributes={this.getInfoAttributes}
            handleExportPdf={this.handleExportPdf}
            handleshowPolygon={this.handleshowPolygon}
            handleDrawConrtol={this.handleDrawConrtol}
            handleExportKml={this.handleExportKml}
            handleModify={this.handleModify}
          />
        )}
        <Widget
          placement="top"
          tooltipText="Select By Polygon"
          handleClick={this.handleDrawConrtol}
          img={selectRed}
          class="selectButton"
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetching: state.mapQuery.fetching,
    data: state.mapQuery.data,
    mapComponent: state.mapReducer.OlMap,
    response_id: state.mapQuery.response_id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    mapQueryPolygon: (layerlist, geometry) =>
      dispatch(mapQueryPolygon(layerlist, geometry)),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectByPolygon);
