import { ButtonToolbar, Modal, Panel, Button } from "react-bootstrap";
import React, { Component } from "react";
import "./ResultModel.css";
import closeButton from "../../../../../../asset/svg/close_icon.svg";

class ResultModel extends Component {
  handleResponse() {
    return this.props.result.map((res) => (
      <h4>
        {" "}
        {res.layer} : {res.count}{" "}
      </h4>
    ));
  }

  render() {
    const count = this.handleResponse();
    return (

        <Panel bsStyle="primary" 
 
        className="resultModal"
        >
          <Panel.Heading>
            <Panel.Title componentClass="h3">Selected Features</Panel.Title>
            <ButtonToolbar className="pull-right button">
              <img
                src={closeButton}
                className="panelheadbtn"
                // onClick={this.props.handleShowResult}
                onClick={this.props.handleCloseResult}
              />
            </ButtonToolbar>
          </Panel.Heading>
          <Panel.Body className="panelBody">
            <p>{count}</p>
            <Button
              bsStyle="danger"
              className="resultbutton"
              // onClick={this.props.handleShowResult}
              onClick={this.props.handleCloseResult}
            >
              OK
            </Button>
          </Panel.Body>
        </Panel>
 
    );
  }
}
export default ResultModel;
