import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Row, Col, Panel, FormGroup, FormControl } from "react-bootstrap";
import "./style.css";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  CategoryScale,
  Legend,
  LinearScale,
  BarElement,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import { getData } from "./data";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale
);
import { getDashboardSummary, getWards } from "./data/action";
import LocalStorageHandler from "../../util/storage";
import { doLogout } from "../user/login/data/actions";
import DashboardLayout from "./DashboardLayout";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diagrams: null,
    };
  }

  componentDidMount() {
    this.props.getDashboardSummary(
      LocalStorageHandler.getLocalBody().local_body_id,
      0
    );
    
    const Localvalues = LocalStorageHandler.getLocalBody().local_body_id;
    if (!this.props.isWardsLoaded)
      this.props.getWards(LocalStorageHandler.getLocalBody().local_body_id);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.dashboardSummary !== this.props.dashboardSummary) {
      this.setState({ diagrams: await getData(this.props.dashboardSummary) });
    }
  }

  handleInputChange = (event) => {
    const target = event.target.value;
    this.props.getDashboardSummary(
      LocalStorageHandler.getLocalBody().local_body_id,
      target
    );
  };

  handleRedirect = (link) => {
    this.props.history.push(link);
  };

  render() {
    const { diagrams } = this.state;
    return (
      <DashboardLayout handleRedirect={this.handleRedirect}>
        <Grid style={{ width: "100%" }}>
          <Row className="show-grid">
            <Col xs={12} md={6} lg={6}>
              <h2 className="dashboard-heading">Dashboard Summary</h2>
            </Col>
            <Col xs={6} md={3} lg={3}>
              <FormGroup controlId="formControlsSelect">
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  className="form-controls"
                  onChange={this.handleInputChange}
                >
                  <option value="0">Select Ward</option>
                  <option value="0">All Wards</option>
                  {this.props.wards &&
                    this.props.wards.map((ward, i) => (
                      <option value={ward.id} key={i}>
                        {ward.ward_name} ({ward.ward_no})
                      </option>
                    ))}
                </FormControl>
              </FormGroup>
            </Col>
            {/*<Col xs={6} md={3} lg={3}>
              <FormGroup controlId="formControlsSelect">
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  className="form-controls"
                  onChange={this.handleInputChange}
                >
                  <option value="">Road</option>
                  <option value="0">All Wards</option>
                  {this.props.wards &&
                    this.props.wards.map((ward, i) => (
                      <option value={ward.id} key={i}>
                        {ward.ward_name} ({ward.ward_no})
                      </option>
                    ))}
                </FormControl>
              </FormGroup>
                    </Col>*/}
            <Row>
              <Col xs={12} md={6} lg={6}>
                <Panel className="diagram-panel1">
                  <Panel.Body>
                    <h5>Building Type</h5>
                    {diagrams && <Bar data={diagrams?.property?.data} />}
                  </Panel.Body>
                </Panel>
              </Col>
              {/* <Col xs={6} md={3} lg={3}>
                  <Panel className="diagram-panel">
                    <Panel.Body>
                      {diagrams && (
                        <Pie
                          data={diagrams?.road_category?.data}
                          style={{ height: "350px" }}
                        />
                      )}
                    </Panel.Body>
                  </Panel>
                </Col> */}
              <Col xs={12} sm={6} md={3} lg={3}>
                <Panel className="diagram-panel2">
                  <Panel.Body>
                    <h5>Road Surface Type</h5>
                    {diagrams && <Pie data={diagrams?.road_material?.data} />}
                  </Panel.Body>
                </Panel>
              </Col>

              <Col xs={12} sm={6} md={3} lg={3}>
                <Panel className="diagram-panel2">
                  <Panel.Body>
                    <h5>Population</h5>
                    {diagrams && <Pie data={diagrams?.population?.data} />}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
            {console.log("diagrams", diagrams)}
            {diagrams &&
              diagrams.counts.map((item, i) => (
                item.title !== "Total Population" && (
                  <Col key={i} xs={12} sm={6} md={3} lg={2}>
                    <Panel className="count-panel">
                      <Panel.Body>
                        <h4>{item.title}</h4>
                        <h1>{item.value}</h1>
                      </Panel.Body>
                    </Panel>
                  </Col>
                )
              ))}
          </Row>
        </Grid>
      </DashboardLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    dashboardSummary: state.dashboard.dashboardSummary,
    wards: state.dashboard.wards,
    isWardsLoaded: state.dashboard.isWardsLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDashboardSummary: (localBodyId, wardId) =>
      dispatch(getDashboardSummary(localBodyId, wardId)),
    getWards: (localBodyId) => dispatch(getWards(localBodyId)),
    doLogout: () => dispatch(doLogout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
