import React, { useEffect, useState } from "react";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import "./DataCollection.css";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import axios from "axios";
import { refreshAccessToken } from "../service/refreshaccesstoken";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CircularProgress from "@mui/material/CircularProgress";
import ProminentAppBar from "./Header";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import api from "../service/apiconfig";
import LocalStorageHandler from "../../../util/storage";
// import { connect } from 'react-redux';
// import { getWards } from '../../dashboard/data/action';

function DataCollection(props) {
  const [dropdown, setDropdown] = useState(false);
  const [wardno, setWardNo] = useState([]);
  const [userData, setUserData] = useState([]);
  const [index, setIndex] = useState("");
  const [singlepdfdownload, setSinglePdfDownload] = useState(false);
  const [multiplepdfdownload, setMultiplePdfDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadicon, setDownloadIcon] = useState(false);
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const currentDate = `${day}/${month}/${year}`;
  const [localbody_name, setLocalBodyName] = useState(
    LocalStorageHandler.getLocalBody().local_body_name
  );
  const [selectedValue, setSelectedValue] = useState(undefined);

  const handleDropDown = () => {
    setDropdown(!dropdown);
  };

  const refreshtoken = localStorage.getItem("refresh_token");

  useEffect(() => {
    const localbody_id = LocalStorageHandler.getLocalBody().local_body_id;

    const handleDataCollection = async () => {
      try {
        const response = await axios.get(
          api + `dashboard/wards/${localbody_id}`
        );
        setWardNo(response.data.wards);
      } catch (error) {
        console.log(error);
      }
    };

    handleDataCollection();
  }, []);

  const handleUserData = async (wardno) => {
    setLoading(true);

    const newAccessToken = LocalStorageHandler.getToken();

    const headers = {
      Authorization: `Bearer ${newAccessToken}`,
    };

    try {
      const response = await axios.get(api + "map/get_ward_data_for_pdf/", {
        params: {
          ward_nu: wardno,
        },
        headers: headers,
      });

      setUserData(JSON.parse(response.data.data));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    setDownloadIcon(true);
  };

  // ****** multiple pdf based on index ******* //

  const handleMultiplePDF = async () => {
    for (let cindex = 0; cindex < userData.length; cindex++) {
      const index = cindex;
      const value = userData[index];

      const ind = Math.round(((index + 1) / userData.length) * 100);
      setIndex(ind);

      setMultiplePdfDownload(true);
      const pdfContent = document.getElementById(`pdf-content-${index}`);

      try {
        const canvas = await html2canvas(pdfContent);

        const imgWidth = 210;
        const pageHeight = 290;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        const doc = new jsPDF("p", "mm", "a4");
        let position = 0;
        const pageData = canvas.toDataURL("image/jpeg", 1.0);
        const imgData = encodeURIComponent(pageData);
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const currentDate = `${day}/${month}/${year}`;
        const cd = `Date : ${currentDate}`;
        const buildingId = value.ar_building_id
          ? `Building ID : ${value.ar_building_id} `
          : `Building ID : NR `;
        doc.setFontSize(5);
        // doc.text(10, 10, cd);
        // doc.text(160, 10, buildingId);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          doc.setLineWidth(5);
          doc.setDrawColor(255, 255, 255);
          doc.rect(0, 0, 210, 295);
          heightLeft -= pageHeight;
        }

        const fileName = `file_${index}.pdf`;

        doc.save(fileName);

        setMultiplePdfDownload(false);
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    }
  };

  // ***** single pdf ****** //

  const handleSinglePDF = async () => {
    setSinglePdfDownload(true);
    const doc = new jsPDF("p", "mm", "a4");

    for (let cindex = 0; cindex < userData.length; cindex++) {
      // userData.length
      const index = cindex;
      const value = userData[index];

      const ind = Math.round(((index + 1) / userData.length) * 100);
      setIndex(ind);

      const pdfContent = document.getElementById(`pdf-content-${index}`);

      try {
        const canvas = await html2canvas(pdfContent);

        const imgWidth = 210;
        const pageHeight = 290;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (index > 0) {
          doc.addPage();
        }

        const pageData = canvas.toDataURL("image/jpeg", 1.0);
        const imgData = encodeURIComponent(pageData);
        let heightLeft = imgHeight;
        let position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const currentDate = `${day}/${month}/${year}`;
        //const cd = `Date : ${currentDate}`;
        const buildingId = value.ar_building_id
          ? `Building ID : ${value.ar_building_id} `
          : `Building ID : NR `;
        doc.setFontSize(5);
        // doc.text(10, 10, `Date : ${currentDate}`);
        // doc.text(160, 10, buildingId);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          doc.setLineWidth(5);
          doc.setDrawColor(255, 255, 255);
          doc.rect(0, 0, 210, 295);
          heightLeft -= pageHeight;
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    }

    const fileName = `singlepage.pdf`;

    doc.save(fileName);
    setSinglePdfDownload(false);
  };

  const extractTextInParentheses = (text) => {
    // console.log("tetx val", text);
    // const text =
    const start = text.indexOf("(");
    const end = text.lastIndexOf(")");
    if (start !== -1 && end !== -1 && end > start) {
      const textInsideParentheses = text.substring(start + 1, end);
      const trimmedText = textInsideParentheses.trim();
      return trimmedText;
    }
    return [];
  };

  const checkValue = (value, alternative = "NR") => {
    return value !== null && value !== undefined && value !== "NA" && value !== 0 ? value : alternative;
  };

  const checkValue2 = (value, alternative = "") => {
    return value !== null && value !== undefined && value !== "NA" && value !== 0 ? value : alternative;
  };

  console.log("userData", userData);

  return (
    <div>
      <ProminentAppBar />

      <div style={{ display: "flex", width: "100%", marginTop: "1rem" }}>
        <div className="Data-collection-icon">
          <ArrowDropDownCircleIcon
            style={{ fontSize: "2rem", color: "black" }}
            onClick={handleDropDown}
          />
        </div>
        {downloadicon && (
          <div className="download-icon">
            {singlepdfdownload ? (
              <p>downloading...{index}%</p>
            ) : (
              <div
                className="single-pdf-download"
                style={{ paddingRight: "10px" }}
              >
                <DownloadForOfflineIcon
                  style={{ fontSize: "2rem", color: "black" }}
                  onClick={() => handleSinglePDF()}
                />
                <span className="single-pdf-text">single pdf</span>
              </div>
            )}

            {multiplepdfdownload ? (
              <p>downloading...{index}%</p>
            ) : (
              <div
                className="multiple-pdf-download"
                style={{ paddingLeft: "10px" }}
              >
                <DownloadForOfflineIcon
                  style={{ fontSize: "2rem", color: "black" }}
                  onClick={() => handleMultiplePDF()}
                />
                <span className="multiple-pdf-text">multiple pdf</span>
              </div>
            )}
          </div>
        )}
      </div>

      {dropdown && (
        <div className="Data-collection-dropdown-show">
          {/* {isWardEmpty ? (<img src='https://cdn.vectorstock.com/i/1000x1000/04/31/no-data-empty-concept-vector-41830431.webp' style={{display:'flex',justifyContent:'center',alignItems:'center',width:'200px',height:'200px'}}/>) :( */}
          <MDBDropdown>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Ward</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Ward"
                  value={selectedValue ?? ""}
                  onChange={(event) => setSelectedValue(event.target.value)}
                >
                  {wardno &&
                    wardno.map((value) => (
                      <MenuItem
                        key={value.ward_no}
                        value={value.ward_no}
                        onClick={() => handleUserData(value.ward_no)}
                      >
                        {value.ward_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </MDBDropdown>
        </div>
      )}

      <div>
        {loading ? (
          <CircularProgress />
        ) : (
          <div style={{ maxHeight: "90vh", overflow: "scroll" }}>
            {userData.length === 0 ? (
              <div>
                <img
                  src="https://cdn.dribbble.com/users/5187354/screenshots/14032905/media/2f4f9ad8d8982a98ddd2ddf4df7d344d.jpg?resize=400x300&vertical=center"
                  alt="No Data Found"
                  style={{ position: "absolute", top: "50%", right: "35%" }}
                />
              </div>
            ) : (
              userData &&
              userData.map((value, index) => (
                <div className="scrocc                                     llable-container" key={index}>
                  <div id={`pdf-content-${index}`}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "0.5rem",
                      }}
                    >
                      <p style={{ fontSize: "12px", paddingLeft: "40px" }}>
                        Date : {currentDate}
                      </p>
                      <p style={{ fontSize: "12px", paddingRight: "40px" }}>
                        {" "}
                        Building ID :{" "}
                        {checkValue2(value.ar_building_id)}
                        {/* {value.building !== 0 &&
                          value.building !== undefined &&
                          value.building !== "NA"
                          ? value.building
                          : "NR"} */}
                        {/* {value.ar_building_id !== 0 ||
                        value.ar_building_id !== undefined ||
                        value.ar_building_id !== "NA"
                          ? value.ar_building_id
                          : "NR"} */}
                      </p>
                    </div>
                    <div
                      className="ward-head"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <h1
                          className="form-title"
                          style={{ textDecoration: "underline black 3px" }}
                        >
                          വിവര ശേഖരണ ഫോറം
                        </h1>
                        <h1
                          className="localbody-title"
                          style={{ fontSize: "13px" }}
                        >
                          {value.localbody_malayalam}
                        </h1>
                      </div>
                    </div>

                    <div className="ward-content">
                      <div className="ward-info">
                        <div>
                          <p>
                            സോണൽ ഓഫീസ്:{" "}
                            {/* {value.z !== null && value.z !== "NA"
                              ? value.z
                              : "NR"} */}
                            {checkValue2(value.ar_zonal_office)}
                          </p>
                          <p>
                            വാർഡിൻ്റെ പേരും നമ്പറും:{" "}
                            {/* {value.ward !== null && value.ward !== "NA"
                              ? value.ward
                              : "NR"} */}
                            {checkValue2(value.ar_new_ward_name)}
                            ,{" "}
                            {/* {value.ward_number !== null &&
                              value.ward_number !== "NA"
                              ? parseInt(value.ward_number)
                              : "NR"} */}
                            {checkValue2(value.ar_new_ward_number)}
                          </p>
                        </div>
                        <div>
                          <p>
                            വർഷം:{" "}
                            {/* {value.year_const !== null &&
                              value.year_const !== "NA" &&
                              value.year_const !== 0
                              ? value.year_const
                              : "NR"} */}
                            {checkValue2(value.ar_new_ward_year)}
                          </p>
                          <p>
                            കെട്ടിട നമ്പർ:{" "}
                            {/* {value.new_pro_id !== null &&
                              value.new_pro_id !== "NA"
                              ? value.new_pro_id
                              : "NR"} */}
                            {checkValue2(value.ar_new_pro_id)}
                          </p>
                        </div>
                      </div>
                      <div>
                        <p>
                          കെട്ടിടത്തിന്‍റെ ഐ ഡി:{" "}
                          {/* {value.ar_building_id !== 0 ||
                          value.ar_building_id !== undefined ||
                          value.ar_building_id !== "NA"
                            ? value.ar_building_id
                            : "NR"} */}
                          {/* {value.building &&
                            (value.building !== 0 &&
                              value.building !== undefined &&
                              value.building !== "NA")
                            ? value.building
                            : "NR"} */}
                          {checkValue2(value.ar_building_id)}
                        </p>

                        <p>
                          വാർഷിക നികുതി:{" "}
                          {
                            parseInt(value.ar_total_tax) === -1
                              ? "NR"
                              : value.ar_total_tax === null || value.ar_total_tax === "NA"
                                ? ""
                                : value.ar_total_tax
                          }
                        </p>
                      </div>
                    </div>

                    <p className="text-sm-start">
                      ഫോറം കെട്ടിട ഉടമ സമർപ്പിച്ച വിവരം :
                    </p>
                    <div className="ward-table">
                      <MDBTable
                        className="mdb-table"
                        bordered
                        style={{ borderColor: "black" }}
                      >
                        <MDBTableHead>
                          <tr>
                            <th>ക്രമ നമ്പർ</th>
                            <th>തരം</th>
                            <th>മാറ്റം വരുത്തിയ തീയ്യതി</th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          <tr style={{ height: "45px", width: "70px" }}>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr style={{ height: "45px", width: "70px" }}>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr style={{ height: "45px", width: "70px" }}>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr style={{ height: "45px", width: "70px" }}>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr style={{ height: "45px", width: "70px" }}>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr style={{ height: "45px", width: "70px" }}>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr style={{ height: "45px", width: "70px" }}>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr style={{ height: "45px", width: "70px" }}>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr style={{ height: "45px", width: "70px" }}>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </MDBTableBody>
                      </MDBTable>
                    </div>
                    <p className="text-sm-start">
                      കെട്ടിടത്തിൻ്റെ മാറ്റം സംബന്ധിച്ച വിവരം :
                    </p>
                    <div>
                      <p
                        className="text-sm-start"
                        style={{ fontSize: "13px", fontWeight: "bolder" }}
                      >
                        ഫീൽഡ് പരിശോധന റിപ്പോർട്ട്‌ :
                      </p>
                    </div>

                    <div className="ward-table-field-report">
                      <MDBTable
                        className="mdb-table2"
                        bordered
                        style={{ borderColor: "black" }}
                      >
                        <MDBTableHead>
                          <tr>
                            <th>ക്രമ നമ്പർ </th>
                            <th>ഇനം </th>
                            <th>നിലവിലെ അസ്സസ്മെന്റ് വിവരങ്ങൾ</th>
                            <th>പരിശോധനയിൽ ബോധ്യപ്പെട്ട വിവരം</th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          <tr>
                            <td colSpan={4}>
                              കെട്ടിട ഉടമ / ഉടമകളുടെ വിവരങ്ങൾ{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>പേര് </td>
                            <td>
                              {/* {value.ar_owner_address &&
                                (value.ar_owner_address !== null &&
                                  value.ar_owner_address !== "NA"
                                  ? value.ar_owner_address
                                    .match(/^[^\(]+/)[0]
                                    .trim()
                                  : "NR")} */}
                              {checkValue2(value.ar_owner_address && value.ar_owner_address.match(/^[^\(]+/)[0].trim())}
                            </td>

                            <td>
                              {value.firstname} {value.firstname !== "NR" && value.firstname !== null && value.firstname !== undefined && value.lastname !== "NA" && value.lastname !== "NR" ? value.lastname : ''}
                              {/* {value.firstname && (value.firstname !== null &&
                                value.firstname !== "NA")
                                ? `${value.firstname} ${value.lastname && (value.lastname !== null || value.lastname !== "NA") ? value.lastname : ""}`
                                : "NR"} */}
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>മേൽവിലാസം </td>
                            <td>
                              {value.ar_owner_address &&
                                (value.ar_owner_address !== null &&
                                  value.ar_owner_address !== "NA")
                                ? extractTextInParentheses(
                                  value.ar_owner_address
                                )
                                : ""}
                            </td>

                            <td>
                              {value.owner_house}
                              , {value.place_name}
                              <br />
                              {value.owner_post}
                              , {value.ownr_pin}
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>മൊബൈൽ നമ്പർ</td>
                            <td>
                              {checkValue2(value.ar_mobile)}
                            </td>
                            <td>
                              {value.mobile}
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>കെട്ടിട ഉടമ/ ഉടമകളുടെ ആധാർ നമ്പർ </td>
                            <td>
                              {checkValue2(value.ar_owner_aadhaar_number)}
                            </td>
                            <td>
                              {value.owner_aadhaar}
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>ഇമെയിൽ ഐ ഡി</td>
                            <td>
                              {" "}
                              <td>
                                {checkValue2(value.ar_owner_email)}
                              </td>
                            </td>
                            <td>
                              {value.owner_email}
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={4}
                              style={{ fontSize: "13px", fontWeight: "bolder" }}
                            >
                              കെട്ടിടത്തിന്‍റെ വിവരങ്ങൾ{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>കെട്ടിടത്തിന്‍റെ തരം </td>
                            <td>
                              {" "}
                              {checkValue2(value.ar_building_status)}
                            </td>
                            <td>
                              {value.bldg_status}
                            </td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>വാർഡ് നമ്പർ </td>
                            <td>
                              {checkValue2(value.ar_new_ward_number)}
                            </td>
                            <td>
                              {value.ward_number}
                            </td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>കെട്ടിട നമ്പർ, ഐ ഡി </td>
                            <td>
                              {" "}
                              {checkValue2(value.ar_new_pro_id)}, {checkValue2(value.ar_building_id)}
                              {" "}
                            </td>
                            <td>
                              {value.new_pro_id}, {checkValue2(value.ar_building_id)}
                              {" "}
                            </td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>വില്ലേജ് </td>
                            <td>
                              {checkValue2(value.ar_village)}
                              {" "}
                            </td>
                            <td>
                              {value.village}
                              {" "}
                            </td>
                          </tr>
                        </MDBTableBody>

                        <MDBTableBody>
                          <tr>
                            <td>10</td>
                            <td>സർവ്വേ നമ്പർ </td>
                            <td>
                              {checkValue2(value.ar_survey_details)}
                              {" "}
                            </td>
                            <td>
                              {/* {checkValue(value.survey_details)} */}
                              {value.owner_survey_no}
                              {" "}
                            </td>
                          </tr>
                          <tr>
                            <td>11</td>
                            <td>തറ വിസ്തീർണം (നിലകൾ തിരിച്ച്) </td>
                            <td>
                              {" "}
                              {parseInt(value.ar_floor_area) !== -2 &&
                                parseInt(value.ar_floor_area) !== -1 &&
                                value.ar_floor_area !== undefined &&
                                value.ar_floor_area !== "NA" ? (
                                <div>
                                  {value.ar_floor_area.split(',').map((item, index) => (
                                    <React.Fragment key={index}>
                                      {index > 0 && <br />}
                                      {item.trim()}
                                    </React.Fragment>
                                  ))}
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              {value.floor_area_details && value.floor_area_details.length > 0 ? (
                                value.floor_area_details.map((floor, index) => (
                                  <div key={index}>
                                    {floor.floor_name} - {floor.floor_area} m²
                                  </div>
                                ))
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>12</td>
                            <td>ഉപയോഗ ക്രമം </td>
                            <td>
                              {/* {value.ar_building_usage !== null &&
                                value.ar_building_usage !== "NA"
                                ? value.ar_building_usage
                                : "NR"} */}
                              {checkValue2(value.ar_building_usage)}
                              {" "}
                            </td>
                            <td>
                              {/* {value.bldg_usage !== null &&
                                value.bldg_usage !== "NA"
                                ? value.bldg_usage
                                : "NR"} */}
                              {value.bldg_usage}
                              {" "}
                            </td>
                          </tr>
                          <tr>
                            <td>13</td>
                            <td>ഉപ -ഉപയോഗ ക്രമം </td>
                            <td>
                              {/* {value.ar_bldg_sbtyp !== null &&
                                value.ar_bldg_sbtyp !== "NA"
                                ? value.ar_bldg_sbtyp
                                : "NR"} */}
                              {checkValue2(value.ar_building_sub_type)}
                              {" "}
                            </td>
                            <td>
                              {/* {value.bldg_sbtyp !== null &&
                                value.bldg_sbtyp !== "NA"
                                ? value.bldg_sbtyp
                                : "NR"} */}
                              {value.bldg_sbtyp}
                              {" "}
                            </td>
                          </tr>
                          <tr>
                            <td>14</td>
                            <td>മേഖല </td>
                            <td>
                              {parseInt(value.ar_zone) === -2
                                ? ""
                                : parseInt(value.ar_zone) === -1
                                  ? ""
                                  : checkValue2(value.ar_zone)}
                            </td>
                            <td>
                              {parseInt(value.bldg_zone) === -2 &&
                                value.bldg_zone === null &&
                                value.bldg_zone === "NA" &&
                                parseInt(value.bldg_zone) === -1
                                ? // ? "NR"
                                // : parseInt(value.bldg_zone) === -1 || value.bldg_zone === null || value.bldg_zone === "NA"
                                ""
                                : value.bldg_zone}
                            </td>
                          </tr>
                          <tr>
                            <td>15</td>
                            <td>റോഡിൻ്റെ പേര് </td>
                            <td>
                              {/* {value.ar_road_name !== null &&
                                value.ar_road_name !== "NA"
                                ? value.ar_road_name
                                : "NR"} */}
                              {checkValue2(value.ar_road_name)}
                              {" "}
                            </td>
                            <td>
                              {/* {value.near_road !== null &&
                                value.near_road !== "NA"
                                ? value.near_road
                                : "NR"} */}
                              {value.near_road}
                            </td>
                          </tr>
                          <tr>
                            <td>16</td>
                            <td>റോഡിൻ്റെ തരം </td>
                            {/* <td>റോഡിൻ്റെ വീതി </td> */}
                            <td>
                              {/* {value.ar_road_type !== null &&
                                value.ar_road_type !== "NA"
                                ? value.ar_road_type
                                : "NR"} */}
                              {/* {checkValue(value.ar_road_type)} */}
                              {checkValue2(value.ar_road_width)}
                            </td>
                            <td>
                              {/* {value.road_type !== null &&
                                value.road_type !== "NA"
                                ? value.road_type
                                : "NR"} */}
                              {/* {checkValue(value.road_type)} */}
                              {value.road_width}
                            </td>
                          </tr>
                          <tr>
                            <td>17</td>
                            <td>മേൽക്കൂരയുടെ തരം </td>
                            <td>
                              {/* {value.ar_roof_details !== null &&
                                value.ar_roof_details !== "NA"
                                ? value.ar_roof_details
                                : "NR"} */}
                              {checkValue2(value.ar_roof_details)}
                            </td>
                            <td>
                              {/* {value.rf_typ_per !== null &&
                                value.rf_typ_per !== "NA"
                                ? value.rf_typ_per
                                : "NR"} */}
                              {value.roof_details[0].roof_type} {checkValue(value.roof_details[0].roof_type) !== "NR" && `(${checkValue(value.roof_details[0].roof_per)}%)`}
                            </td>
                          </tr>
                          <tr>
                            <td>18</td>
                            <td>തറയുടെ നിർമ്മിതി </td>
                            <td>
                              {/* {value.ar_flr_typ && (value.ar_flr_typ !== null &&
                                value.ar_flr_typ !== "NA")
                                ? value.ar_flr_typ
                                : "NR"} */}
                              {/* {checkValue(value.ar_flr_typ)} */}
                              {checkValue2(value.ar_floor_details)}
                            </td>
                            <td>
                              {/* {value.flr_typ !== null &&
                                value.ar_flr_typ !== "NA"
                                ? value.flr_typ
                                : "NR"} */}
                              {value.flr_typ}
                            </td>
                          </tr>
                          <tr style={{ height: "70px", width: "70px" }}>
                            <td>19</td>
                            <td>
                              കേന്ദ്രീകൃത എയർകണ്ടീഷനിംഗ് സൗകര്യം (ഉണ്ട് /ഇല്ല )
                            </td>
                            <td>
                              {/* {value.ar_ac !== null && value.ar_ac !== "NA"
                                ? value.ar_ac
                                : "NR"} */}
                              {checkValue2(value.ar_ac)}
                            </td>
                            <td>
                              {/* {value.central_ac !== null &&
                                value.central_ac !== "NA"
                                ? value.central_ac
                                : "NR"} */}
                              {value.central_ac}
                            </td>
                          </tr>
                          <tr style={{ height: "70px", width: "70px" }}>
                            <td>20</td>
                            <td>
                              നികുതിയിൽ നിന്നും ഒഴിവാക്കപ്പെട്ടിട്ടുണ്ടോ
                              (ഉണ്ടെങ്കിൽ തരം )
                            </td>
                            <td>
                              {/* {value.ar_tax_exemption && (value.ar_tax_exemption !== null &&
                                value.ar_tax_exemption !== "NA")
                                ? value.ar_tax_exemption
                                : "NR"} */}
                              {checkValue2(value.ar_tax_exemption)}
                            </td>
                            <td>
                              {/* {value.tax_exemption && (value.tax_exemption !== null &&
                                value.tax_exemption !== "NA")
                                ? value.tax_exemption
                                : "NR"} */}
                              {(() => {
                                const exemption = value.tax_exemption
                                return exemption !== "NR" ? `${exemption} - ${value.tax_exemption_reason}` : exemption
                              })()}
                            </td>
                          </tr>
                          <tr style={{ height: "70px", width: "70px" }}>
                            <td>21</td>
                            <td>
                              നീന്തൽക്കുളം ഉണ്ടോ (ഉണ്ടെങ്കിൽ വിസ്തീർണ്ണം )
                            </td>
                            <td>
                              {" "}
                              {checkValue2(value.ar_swimming_pool_area)}
                              {/* {value.ar_swimming_pool_area !== null &&
                                value.ar_swimming_pool_area !== "NA"
                                ? value.ar_swimming_pool_area
                                : "NR"} */}
                            </td>
                            <td>
                              {/* {value.swimming_pool_area !== null ||
                              value.swimming_pool_area !== "NA"
                                ? value.swimming_pool_area
                                : "NR"} */}
                              {value.swimming_pool && value.swimming_pool !== "NA" ? value.swimming_pool : ""}
                              {value.swimming_pool_area && value.swimming_pool_area !== "NA" ? value.swimming_pool_area : ""}
                              {/* {value.swimming_pool && (value.swimming_pool !== null &&
                                value.swimming_pool !== "NA") ? `${value.swimming_pool} ${value.swimming_pool_area && (value.swimming_pool_area !== null ||
                                  value.swimming_pool_area !== "NA") ? 
                                  value.swimming_pool_area : ""}` : ""} */}
                            </td>
                          </tr>
                          <tr style={{ height: "70px", width: "70px" }}>
                            <td>22</td>
                            <td>GPS Latitude</td>
                            <td>{checkValue2(value.ar_geom?.coordinates[0])}</td>
                            <td>
                              {/* {value.geom.coordinates[0] !== null &&
                                value.geom.coordinates[0] !== "NA"
                                ? value.geom.coordinates[0]
                                : "NR"} */}
                              {value.geom?.coordinates[0]}
                            </td>
                          </tr>
                          <tr style={{ height: "70px", width: "70px" }}>
                            <td>23</td>
                            <td>GPS Longitude</td>
                            <td>{checkValue2(value.ar_geom?.coordinates[1])}</td>
                            <td>
                              {/* {value.geom.coordinates[1] !== null &&
                                value.geom.coordinates[1] !== null
                                ? value.geom.coordinates[1]
                                : "NR"} */}
                              {value.geom.coordinates[1]}
                            </td>
                          </tr>
                        </MDBTableBody>
                      </MDBTable>
                    </div>
                    <div className="data-collection-end">
                      <p className="text-sm-start">
                        പരിശോധനയിൽ നിലവിലെ അസ്സസ്മെന്റ് വിവരങ്ങളിൽ എന്തെങ്കിലു
                        മാറ്റങ്ങൾ കണ്ടെത്തിയിട്ടുണ്ടെങ്കിൽ, മാറ്റം വന്ന തീയ്യതി
                        :
                      </p>
                      <p className="text-sm-start"> റിമാർക്സ് :</p>
                      <p className="text-sm-start">
                        സർവ്വേ നടത്തുന്ന വ്യക്തിയുടെ പേര് :
                      </p>
                    </div>
                    <hr className="footer-line" />
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default DataCollection;
