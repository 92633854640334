import {
  GET_LAYERS,
  GET_COLUMNS,
  FETCH_LAYER_COLUMNS,
  TOGGLE_REPORT_MODEL,
  FETCHING_DATA,
  FETCH_DATA_FAILED,
  FETCH_DATA_SUCCESS,
  FETCHING_VALUE,
  FETCH_VALUE_FAILED,
  FETCH_VALUE_SUCCESS,
  ADVANCESEARCH_VALUE_FETCHING,
  ADVANCESEARCH_VALUE_FETCH_SUCCESS,
  ADVANCESEARCH_VALUE_FETCH_FAILED,
  REPORT_VALUE_FETCHING,
  REPORT_VALUE_FETCH_SUCCESS,
  REPORT_VALUE_FETCH_FAILED,
  GENERATE_REPORT,
  GENERATE_REPORT_SUCCESS,
  GENERATE_REPORT_FAILED,
  GET_REPORT,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILED,
  FETCHING_SEARCHED_LAYER_SUCCESS,
  DOWNLOAD_REPORT,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_FAILED,
  CLEAR_FILTER_INIT_ACTION,
  CLEAR_DOWNLOAD_REPORT_INIT_ACTION,
  GET_TEMPLATE_INIT_ACTION,
  GET_TEMPLATE_INIT_ACTION_SUCCESS,
  GET_TEMPLATE_INIT_ACTION_FAIL,
  DELETE_TEMPLATE_INIT_ACTION,
  SAVE_TEMPLATE_INIT_ACTION,
  SAVE_TEMPLATE_INIT_ACTION_SUCCESS,
  SAVE_TEMPLATE_FAIL_ACTION,
  SAVE_TEMPLATE_COMPLETE_ACTION,
  DELETE_TEMPLATE_COMPLETE_ACTION,
  DELETE_TEMPLATE_INIT_ACTION_SUCCESS,
} from "./constants";

const initialState = {
  layers: [],
  attributes: [],
  layerColumns: [],
  showReport: false,
  fetching: false,
  data: null,
  response_id: null,
  response_table: null,
  responseData: null,
  tableData: null,
  recordCount: 0,
  report_result: null,
  reportLink: null,
  memberLink: null,
  fetchReportLoading: false,
  memberData: null,
  reportUrls: [],
  reportFile: null,
  downloadLoading: false,
  unique_id: null,
  page: 1,
  maxPage: 1,
  response_member: null,
  advanceLoading: false,
  savedTemplate: [],
  isSave: false,
  isDeleted: false,
  // feature_data:null,
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_LAYERS:
      return {
        ...state,
        layers: action.layers,
      };
    case GET_COLUMNS:
      return {
        ...state,
        attributes: action.attributes,
      };
    case FETCH_LAYER_COLUMNS:
      return {
        ...state,
        layerColumns: action.response.data,
      };
    case TOGGLE_REPORT_MODEL:
      return {
        showReport: !state.showReport,
      };
    case FETCHING_DATA:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.response.data.data,
      };
    case FETCH_DATA_FAILED:
      return {
        ...state,
        fetching: false,
      };
    case FETCHING_VALUE:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_VALUE_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.response.data,
      };
    case FETCH_VALUE_FAILED:
      return {
        ...state,
        fetching: false,
      };
    case ADVANCESEARCH_VALUE_FETCHING:
      return {
        ...state,
        fetching: true,
        reportFile: null,
        downloadLoading: false,
        advanceLoading: true,
      };
    case ADVANCESEARCH_VALUE_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        // api response for python - id's of features
        response_id: action.response.data.data,
        response_table: action.response.data.advance_data
          ? action.response.data.advance_data
          : [],

        response_member: action.response.data ? action.response.data.data1 : [],

        unique_id: action.response.data ? action.response.data.unique_id : "",

        recordCount: action.response.data ? action.response.data.count : 0,

        reportUrls: action.response.data ? action.response.data.urls_new : [],
        advanceLoading: false,
      };
    case ADVANCESEARCH_VALUE_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
        advanceLoading: false,
      };
    case REPORT_VALUE_FETCHING:
      return {
        ...state,
        fetching: true,
        fetchReportLoading: true,
        reportFile: null,
        downloadLoading: false,

        memberData: null,

        tableData: [],
        recordCount: 0,

        // memberData: [],
        unique_id: "",
      };
    case REPORT_VALUE_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        tableData: action.response.data ? action.response.data.data : [],
        recordCount: action.response.data ? action.response.data.count : 0,
        fetchReportLoading: false,
        memberData: action.response.data ? action.response.data.data1 : [],
        reportUrls: action.response.data ? action.response.data.urls_new : [],
        // reportUrls: action.response.data ? action.response.data.file_path: [],
        unique_id: action.response.data ? action.response.data.unique_id : "",
      };
    case REPORT_VALUE_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
        fetchReportLoading: false,
        tableData: [],
        recordCount: 0,
        memberData: [],
        reportUrls: [],
      };
    case GENERATE_REPORT:
      return {
        ...state,
        fetching: true,
      };
    case GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        fetching: false,
        report_result: action.response.data.message
          ? action.response.data.message
          : "",
      };
    case GENERATE_REPORT_FAILED:
      return {
        ...state,
        fetching: false,
      };

    case GET_REPORT:
      return {
        ...state,
        fetching: true,
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        fetching: false,
        reportLink: action.response.data.url,
        memberLink: action.response.data.member_file_url,
      };
    case GET_REPORT_FAILED:
      return {
        ...state,
        fetching: false,
      };
    case FETCHING_SEARCHED_LAYER_SUCCESS:
      return {
        ...state,
        responseData: action.response.data,
      };

    //download functionality

    case DOWNLOAD_REPORT:
      return {
        ...state,
        fetching: true,
        reportFile: null,
        downloadLoading: true,
        // page: action.payload.page,
        maxPage: action.payload.maxPage,
      };
    case DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        fetching: false,
        reportFile: action.response.data ? action.response.data : null,
        downloadLoading: false,
        page: state.page + 1,
      };
    case DOWNLOAD_REPORT_FAILED:
      return {
        ...state,
        fetching: false,
        downloadLoading: false,
      };

    case CLEAR_FILTER_INIT_ACTION:
      return {
        ...state,
        memberData: null,
        reportUrls: [],
        reportFile: null,
        tableData: [],
        recordCount: 0,
        fetchReportLoading: false,
        memberData: [],
        unique_id: "",
      };

    case CLEAR_DOWNLOAD_REPORT_INIT_ACTION:
      return {
        ...state,

        reportFile: null,
      };

    case GET_TEMPLATE_INIT_ACTION:
      return {
        ...state,

        savedTemplate: [],
        fetching: true,
      };
    case GET_TEMPLATE_INIT_ACTION_SUCCESS:
      return {
        ...state,

        savedTemplate: action.response.data,
        fetching: false,
      };
    case GET_TEMPLATE_INIT_ACTION_FAIL:
      return {
        ...state,

        savedTemplate: [],
        fetching: false,
      };

    case DELETE_TEMPLATE_INIT_ACTION:
      return {
        ...state,

        savedTemplate: action.payload.savedTemplate,
        isDeleted: false,
      };

    case SAVE_TEMPLATE_INIT_ACTION:
      return {
        ...state,

        isSave: false,
      };
    case SAVE_TEMPLATE_INIT_ACTION_SUCCESS:
      return {
        ...state,
        isSave: true,
      };

    case SAVE_TEMPLATE_FAIL_ACTION:
      return {
        ...state,
        isSave: false,
      };

    case SAVE_TEMPLATE_COMPLETE_ACTION:
      return {
        ...state,
        isSave: false,
      };

    case DELETE_TEMPLATE_COMPLETE_ACTION:
      return {
        ...state,
        isDeleted: false,
      };

    case DELETE_TEMPLATE_INIT_ACTION_SUCCESS:
      return {
        ...state,
        isDeleted: true,
      };

    default:
      return state;
  }
}
