import {
  GET_LAYERS,
  GET_COLUMNS,
  FETCH_LAYER_COLUMNS,
  TOGGLE_REPORT_MODEL,
  FETCHING_DATA,
  FETCH_DATA_FAILED,
  FETCH_DATA_SUCCESS,
  FETCHING_VALUE,
  FETCH_VALUE_FAILED,
  FETCH_VALUE_SUCCESS,
  ADVANCESEARCH_VALUE_FETCHING,
  ADVANCESEARCH_VALUE_FETCH_SUCCESS,
  ADVANCESEARCH_VALUE_FETCH_FAILED,
  REPORT_VALUE_FETCHING,
  REPORT_VALUE_FETCH_SUCCESS,
  REPORT_VALUE_FETCH_FAILED,
  GENERATE_REPORT,
  GENERATE_REPORT_SUCCESS,
  GENERATE_REPORT_FAILED,
  GET_REPORT,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILED,
  FETCHING_SEARCHED_LAYER_SUCCESS,
  DOWNLOAD_REPORT,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_FAILED,
  CLEAR_FILTER_INIT_ACTION,
  CLEAR_DOWNLOAD_REPORT_INIT_ACTION,
  GET_TEMPLATE_INIT_ACTION_FAIL,
  GET_TEMPLATE_INIT_ACTION_SUCCESS,
  GET_TEMPLATE_INIT_ACTION,
  SAVE_TEMPLATE_INIT_ACTION,
  SAVE_TEMPLATE_INIT_ACTION_SUCCESS,
  SAVE_TEMPLATE_FAIL_ACTION,
  DELETE_TEMPLATE_INIT_ACTION,
  DELETE_TEMPLATE_INIT_ACTION_SUCCESS,
  DELETE_TEMPLATE_INIT_ACTION_FAIL,
  SAVE_TEMPLATE_COMPLETE_ACTION,
  DELETE_TEMPLATE_COMPLETE_ACTION,
} from "./constants";
import localStorageHandler from "../../../../../util/storage/";
import mapSearchService from "../../../../../service/MapSearchService";
import { loadingActions } from "../../../../../components/loader/data/action";
import axios from "axios";
import "ol/ol.css";
import { AUTH_KEY } from "../../../../../config";

const GetSortOrder = (prop) => {
  return function(a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
};

export const getLayers = () => {
  return (dispatch) => {
    let layerTree = localStorageHandler.getLayerCategory();
    let layers = [];
    layerTree.map((treeItem, index) => {
      if (treeItem.hasOwnProperty("children")) {
        treeItem.children.map((layer) => {
          layers.push(layer);
        });
      }
    });
    layers.sort(GetSortOrder("label"));
    dispatch(setLayers(layers));
  };
  function setLayers(layers) {
    return { type: GET_LAYERS, layers };
  }
};

export const getColumns = () => {
  return (dispatch) => {
    let layerTree = localStorageHandler.getLayerCategory();
    let attributes = [];
    layerTree.map((treeItem, index) => {
      if (treeItem.hasOwnProperty("children")) {
        treeItem.children.map((layer) => {
          if (layer.hasOwnProperty("detailedInfoAttributes")) {
            // layer.detailedInfoAttributes.map(column => {
            attributes.push(layer);
          }
          // )};
        });
      }
    });
    dispatch(setColumns(attributes));
  };
  function setColumns(attributes) {
    return { type: GET_COLUMNS, attributes };
  }
};

export const fetchColumnsByLayer = (layer) => {
  return async (dispatch) => {
    dispatch(loadingActions.isloading());
    await axios
      .get(layer.url.concat("wfs"), {
        params: {
          version: "1.3.0",
          request: "describeFeatureType",
          outputFormat: "application/json",
          service: "WFS",
          typeName: layer.layer_name,
          authkey: AUTH_KEY,
        },
      })
      .then((response) => {
        dispatch(loadingActions.loadingComplete());
        dispatch(success(response));
      });
  };
  function success(response) {
    return { type: FETCH_LAYER_COLUMNS, response };
  }
};

export const togleReportModel = () => {
  return (dispatch) => {
    dispatch(toggleReportModel());
  };
  function toggleReportModel() {
    return { type: TOGGLE_REPORT_MODEL };
  }
};

export const fetchDataByAttribute = (searchBy, query, table) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService
      .fetchDataByAttribute(searchBy, query, table)
      .then((response) => {
        if (response) {
          if (response.data) dispatch(success(response));
          dispatch(loadingActions.loadingComplete());
        } else {
          dispatch(failure(response));
          dispatch(loadingActions.loadingComplete());
        }
      });
    function request() {
      return { type: FETCHING_DATA };
    }
    function success(response) {
      return { type: FETCH_DATA_SUCCESS, response };
    }
    function failure(error) {
      return { type: FETCH_DATA_FAILED, error };
    }
  };
};

export const fetchValueByAttribute = (
  model,
  attribute,
  data_type,
  category_field,
  category_id,
  isSearch,
  searchParam
) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService
      .fetchValueByAttribute(
        model,
        attribute,
        data_type,
        category_field,
        category_id,
        isSearch,
        searchParam
      )
      .then((response) => {
        if (response) {
          dispatch(success(response));
          dispatch(loadingActions.loadingComplete());
        } else {
          dispatch(failure(response));
          dispatch(loadingActions.loadingComplete());
        }
      });
    function request() {
      return { type: FETCHING_VALUE };
    }
    function success(response) {
      return { type: FETCH_VALUE_SUCCESS, response };
    }
    function failure(error) {
      return { type: FETCH_VALUE_FAILED, error };
    }
  };
};

export const fetchDataByFilter = (filterValues) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService.fetchDataByFilter(filterValues).then((response) => {
     
      if (response) {
        dispatch(success(response));
        dispatch(loadingActions.loadingComplete());
      } else {
        dispatch(failure(response));
        dispatch(loadingActions.loadingComplete());
      }
    });
    function request() {
      return { type: ADVANCESEARCH_VALUE_FETCHING };
    }
    function success(response) {
      return { type: ADVANCESEARCH_VALUE_FETCH_SUCCESS, response };
    }
    function failure(error) {
      return { type: ADVANCESEARCH_VALUE_FETCH_FAILED, error };
    }
  };
};

export const requestSearchedLayer = (
  layerToSearch,
  searchValue
  // parameters
) => {
  return async (dispatch) => {
    dispatch(loadingActions.isloading());
    if (layerToSearch.url)
      await axios
        .get(layerToSearch.url.concat("wfs"), {
          params: {
            version: "1.3.0",
            outputFormat: "application/json",
            request: "GetFeature",
            service: "WFS",
            srsName: "EPSG:3857",
            typeName: layerToSearch.layer_name,
            featureID: searchValue,
            authkey: AUTH_KEY,
            // propertyName: parameters,
          },
        })
        .then((response) => {
          dispatch(success(response));
          dispatch(loadingActions.loadingComplete());
        });
  };
  function success(response) {
    return { type: FETCHING_SEARCHED_LAYER_SUCCESS, response };
  }
};

export const fetchDataInReport = (filterValues) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService.fetchDataInReport(filterValues).then((response) => {
     
      if (response) {
        dispatch(success(response));
        dispatch(loadingActions.loadingComplete());
      } else {
        dispatch(failure(response));
        dispatch(loadingActions.loadingComplete());
      }
    });
    function request() {
      return { type: REPORT_VALUE_FETCHING };
    }
    function success(response) {
      return { type: REPORT_VALUE_FETCH_SUCCESS, response };
    }
    function failure(error) {
      return { type: REPORT_VALUE_FETCH_FAILED, error };
    }
  };
};

export const generateReport = (reportValues) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService.generateReport(reportValues).then((response) => {
      if (response) {
        dispatch(success(response));
        dispatch(loadingActions.loadingComplete());
      } else {
        dispatch(failure(response));
        dispatch(loadingActions.loadingComplete());
      }
    });
    function request() {
      return { type: GENERATE_REPORT };
    }
    function success(response) {
      return { type: GENERATE_REPORT_SUCCESS, response };
    }
    function failure(error) {
      return { type: GENERATE_REPORT_FAILED, error };
    }
  };
};

export const getReportLink = (reportValues) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService.getReportLink(reportValues).then((response) => {
      if (response) {
        dispatch(success(response));
        dispatch(loadingActions.loadingComplete());
      } else {
        dispatch(failure(response));
        dispatch(loadingActions.loadingComplete());
      }
    });
    function request() {
      return { type: GET_REPORT };
    }
    function success(response) {
      return { type: GET_REPORT_SUCCESS, response };
    }
    function failure(error) {
      return { type: GET_REPORT_FAILED, error };
    }
  };
};

//Download Report

export const downloadReport = (reportValues) => {
  return (dispatch) => {
    dispatch(request(reportValues));
    dispatch(loadingActions.isloading());
    mapSearchService.downloadReport(reportValues).then((response) => {
      if (response) {
        dispatch(success(response));
        dispatch(loadingActions.loadingComplete());
      } else {
        dispatch(failure(response));
        dispatch(loadingActions.loadingComplete());
      }
    });
    function request(reportValues) {
      return { type: DOWNLOAD_REPORT, payload: reportValues };
    }
    function success(response) {
      return { type: DOWNLOAD_REPORT_SUCCESS, response };
    }
    function failure(error) {
      return { type: DOWNLOAD_REPORT_FAILED, error };
    }
  };
};

export const clearFilterInitAction = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_FILTER_INIT_ACTION,
    });
  };
};

export const clearDownLoadInitAction = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_DOWNLOAD_REPORT_INIT_ACTION,
    });
  };
};

export const saveReportTemplate = (reportValues) => {
  return (dispatch) => {
    dispatch(request(reportValues));
    dispatch(loadingActions.isloading());
    mapSearchService.saveReportTemplate(reportValues).then((response) => {
      if (response) {
        dispatch(success(response));

        setTimeout(() => dispatch(complete), 5000);

        dispatch(loadingActions.loadingComplete());
      } else {
        dispatch(failure(response));
        dispatch(loadingActions.loadingComplete());
      }
    });
    function request(reportValues) {
      return { type: SAVE_TEMPLATE_INIT_ACTION, payload: reportValues };
    }
    function success(response) {
      return { type: SAVE_TEMPLATE_INIT_ACTION_SUCCESS, response };
    }
    function failure(error) {
      return { type: SAVE_TEMPLATE_FAIL_ACTION, error };
    }
    function complete(response) {
      return { type: SAVE_TEMPLATE_COMPLETE_ACTION, response };
    }
  };
};

export const getReportTemplate = (reportValues) => {
  return (dispatch) => {
    dispatch(request(reportValues));
    dispatch(loadingActions.isloading());
    mapSearchService.getReportTemplate(reportValues).then((response) => {
      if (response) {
        dispatch(success(response));
        dispatch(loadingActions.loadingComplete());
      } else {
        dispatch(failure(response));
        dispatch(loadingActions.loadingComplete());
      }
    });
    function request(reportValues) {
      return { type: GET_TEMPLATE_INIT_ACTION, payload: reportValues };
    }
    function success(response) {
      return { type: GET_TEMPLATE_INIT_ACTION_SUCCESS, response };
    }
    function failure(error) {
      return { type: GET_TEMPLATE_INIT_ACTION_FAIL, error };
    }
  };
};

export const deleteReportTemplate = (reportValues) => {
  return (dispatch) => {
    dispatch(request(reportValues));
    dispatch(loadingActions.isloading());
    mapSearchService.deleteReportTemplate(reportValues).then((response) => {
      if (response) {
        dispatch(success(response));
        dispatch(loadingActions.loadingComplete());
        setTimeout(() => dispatch(complete), 5000);
      } else {
        dispatch(failure(response));
        dispatch(loadingActions.loadingComplete());
      }
    });
    function request(reportValues) {
      return { type: DELETE_TEMPLATE_INIT_ACTION, payload: reportValues };
    }
    function success(response) {
      return { type: DELETE_TEMPLATE_INIT_ACTION_SUCCESS, response };
    }
    function failure(error) {
      return { type: DELETE_TEMPLATE_INIT_ACTION_FAIL, error };
    }

    function complete(error) {
      return { type: DELETE_TEMPLATE_COMPLETE_ACTION, error };
    }
  };
};
