import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { loadingActions } from "../../components/loader/data/action";
import { connect } from "react-redux";
import DataCollection from "./DataCollection/DataCollection";
import { snackbarActions } from "../snackbar/data/action";
import { setPageHeader } from "../../containers/page_layout/data/action";
import {
  getLayers,
  fetchColumnsByLayer,
  fetchDataInReport,
  requestSearchedLayer,
  generateReport,
  getReportLink,
  downloadReport,
  clearFilterInitAction,
  clearDownLoadInitAction,
  saveReportTemplate,
  getReportTemplate,
  deleteReportTemplate,
} from "../map_viewer/map_header/advanced_filter/data/action";
import { toggleAdvancedFilterWindow } from "../map_viewer/map_header/navigation/navbar_form/data/action";
import Filter from "./components/Filter";
import Attribute from "./components/Attribute";
import getAttributeName from "../../util/attributes/";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import backArrow from "../../asset/svg/back.svg";
import localStorageHandler from "../../util/storage";
import "./Report.css";
import ResultModel from "./components/ResultModel";
import {
  Button,
  Badge,
  FormGroup,
  Col,
  FormControl,
  ControlLabel,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import Loader from "../loader/Loader";
import TablePagination from "../pagination/pagination";
import ConfirmBox from "../confirmation_box";
import Pusher from "pusher-js";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import NoDataImg from "../../asset/png/no-results.png";
import SavedTemplate from "./components/Template";
import InputBoxModal from "../confirmation_box/inputBoxModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PUSHER_KEY } from "../../../src/config";
// import Modal from "react-bootstrap/Modal";
var temp;
var channel;
class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: false,
      showAtrbt: false,
      reportStatus: false,
      filterFormCount: [],
      result: [],
      index: 0,
      layers: {},
      layerCategorys: localStorageHandler.getLayerCategory(),
      data: {
        layer: "",
        layerCategory: "",
        reportName: "",
        display_name: "",
        fileFormat: "",
        layerName: "",
        fileType: "",
      },
      layer: null,
      params: "",
      parameters: [],
      columnlist: [],
      count: 0,
      propertyName: "",
      showSubmitSelect: false,
      temp: [],
      page: "1",
      limit: "10",
      recordCount: 0,
      parentModel: "",
      modelList: [],
      array: [],
      showDetailsTable: false,
      members: [],
      detailsTitle: "",
      percentage: 0,
      downloadPercentage: 0,
      fileName: "",
      memberData: [],
      downloadConfirmation: false,
      readyToDownloadFlag: false,
      downloadPage: 1,
      downloadLimit: 100,
      isDownloading: false,
      showTemplate: false,
      resultParams: null,
      isShowSaveTemplateSave: false,
      saveTemplateFileName: "",
    };
  }

  handleIsNoFilter = () => {
    this.setState({ showConfirmation: true });

    let parentModel = this.state.data.layer.advancefilterinfo.filter(
      (attribute) => {
        return attribute.parent_model;
      }
    );

    // To get model names of selected columns in a list
    // List should contain the parent model name as first element
    let parentModelList = {};
    parentModelList = [parentModel[0].parent_model];
    let modelNames = {};
    modelNames = this.state.parameters.map((model) => {
      return model.model_name;
    });
    const finalModelList = [...parentModelList, ...modelNames];
    // To eliminate duplicate elements
    let modelList = [...new Set(finalModelList)];

    // logic: Id field of the parent model must be send along with the selected columns
    let idColumn = {
      field: "id",
      model_name: parentModel[0].parent_model,
      display_name: "ID Field",
    };
    this.state.parameters.push(idColumn);

    // Data to be send in request
    let filter = {
      page: "1",
      limit: this.state.limit,
      report: "False",
      file_name: this.state.fileName,
      file_extension: "",
      models: modelList,
      columns: this.state.parameters,
      layer_name: this.state.data.layerName,
      parent_model: parentModel[0].parent_model,
      obj: this.getFilter(this.state.data),
      category_field: this.state.data.layer.category_field,
      category_id: this.state.data.layer.category_id,
    };
    this.setState({
      parentModel: parentModel[0].parent_model,
      modelList: modelList,
    });
    this.props.fetchDataInReport(filter);
    this.setState({
      resultParams: filter,
    });
  };
  handleToUpdate = (value, index) => {
    this.setState({
      data: { ...this.state.data, ["temp".concat(index)]: value },
    });
  };

  handleClearValue = (value, index) => {
    this.setState({
      data: {
        ...this.state.data,
        ["temp".concat(index)]: value,
        ["value".concat(index)]: "",
      },
    });
  };

  componentDidMount() {
    this.props.clearFilterInitAction();
    this.props.setPageHeader("", "", "");
    this.props.getLayers();
    const pusher = new Pusher(PUSHER_KEY, {
      cluster: "ap2",
      encrypted: true,
    });
    channel = pusher.subscribe("my-channel");
    channel.bind("my-event", (data) => {
      if (data.percentage > this.state.percentage)
        this.setState({ percentage: data.percentage });
    });
  }
  componentWillUnmount() {
    channel.unsubscribe("my-channel");
  }

  //USED IN FILTER FORM
  handleInputChange = (event, layer) => {
    this.setState(
      {
        data: { ...this.state.data, [event.target.name]: event.target.value },
      },
      () => {
        this.props.clearDownLoadInitAction();

        if (layer && layer === "layer") {
          this.clearLayer();
        }
      }
    );
  };

  handleInputChangeLimit = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleInputChangeAttribute = (
    event,
    index,
    field,
    model_name,
    parent_model,
    isClearValue
  ) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          [event.target.name]: event.target.value,
          ["display_name".concat(index)]: event.target.value,
          ["attributeField".concat(index)]: field,
          ["model".concat(index)]: model_name,
          ["parentModel".concat(index)]: parent_model,
        },
      },
      () => {
        isClearValue && this.handleClearValue([], index);
      }
    );
  };

  handleInputChangeOperation = (event) => {
    this.props.showSnackbar("Please enter the Value");
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleInputChangeDropdown = (value, index) => {
    this.setState({
      data: { ...this.state.data, ["value".concat(index)]: value },
    });
  };
  // TO ADD FILTER AND VISIBILITY OF FILTER COMPONENT
  filterCheck = () => {
    if (this.state.filterFormCount.length > 0)
      this.setState({
        showSubmitSelect: true,
      });
    else if (this.state.filterFormCount.length > 0)
      this.setState({
        showSubmitSelect: false,
      });
  };

  addFilterForm = (type) => {
    if (!this.props.show) {
      this.props.hide();
    }
    this.props.showSnackbar("Please select an attribute");
    if (
      (this.state.filterFormCount.length <= 8 &&
        type == "Add" &&
        this.state.filterFormCount.length == 0) ||
      (this.state.filterFormCount.length <= 8 && type == "AddMore")
    ) {
      this.setState(
        {
          filterFormCount: [...this.state.filterFormCount, 1],
          data: {
            ...this.state.data,
            ["operation".concat(this.state.index)]: "=",
            ["temp".concat(this.state.index)]: [],
          },
          index: this.state.index + 1,
        },
        () => {
          if (this.state.index >= 2) {
            this.setState({
              data: {
                ...this.state.data,
                ["condition".concat([this.state.index - 1])]: "and",
              },
            });
          }
        }
      );
    }
  };

  //Delete row form

  deleteField = async (index) => {
    let tempData = this.state.data;
    let count = this.state.filterFormCount;
    delete tempData["value".concat(index)];
    delete tempData["operation".concat(index)];
    delete tempData["temp".concat(index)];
    delete tempData["condition".concat(index) + 1];
    delete tempData["column".concat(index)];

    if (index < this.state.filterFormCount.length) {
      for (let i = index; i < this.state.filterFormCount.length; i++) {
        tempData["value".concat(i)] = tempData["value".concat(i + 1)];
        tempData["operation".concat(i)] = tempData["operation".concat(i + 1)];
        tempData["temp".concat(i)] = tempData["temp".concat(i + 1)];
        tempData["condition".concat(i)] = tempData["condition".concat(i) + 1];
        tempData["column".concat(i)] = tempData["column".concat(i + 1)];

        if (i === this.state.filterFormCount.length) {
          delete tempData["value".concat(i)];
          delete tempData["operation".concat(i)];
          delete tempData["temp".concat(i)];
          delete tempData["condition".concat(i) + 1];
          delete tempData["column".concat(i)];
        }
      }
    }

    this.setState({ data: { ...this.state.data, tempData } });
    count.pop();
  };

  deleteAllFeilds = () => {
    if (this.state.index > 0) {
      let tempData = this.state.data;
      for (let i = 0; i < this.state.index; i++) {
        delete tempData["value".concat(i)];
        delete tempData["operation".concat(i)];
        delete tempData["temp".concat(i)];
        delete tempData["condition".concat(i) + 1];
        delete tempData["column".concat(i)];
      }

      this.setState({
        data: { ...this.state.data, tempData },
        reportStatus: false,
        filterFormCount: [],
        index: 0,
      });
    }
  };

  clearFilter = () => {
    this.deleteAllFeilds();
  };

  //FORMATING FILTER (CONDITIONS)
  getFilter = (data) => {
    let filter = "";
    let obj = [];
    Object.keys(data).map((key) => {
      if (key.includes("column")) {
        if (data["condition".concat(key.substr(-1))]) {
          filter = filter
            .concat(" ")
            .concat(data["condition".concat(key.substr(-1))])
            .concat(" ");
        }
        filter = filter
          .concat(data[key])
          .concat(data["operation".concat(key.substr(-1))])
          .concat("'")
          .concat(data["value".concat(key.substr(-1))])
          .concat("'");

        if (
          data["display_name".concat(key.substr(-1))] &&
          data["operation".concat(key.substr(-1))] != "=" &&
          data["value".concat(key.substr(-1))]
        ) {
          obj.push({
            // attribute: data[key],
            display_name: data["display_name".concat(key.substr(-1))],
            attribute: data["attributeField".concat(key.substr(-1))],
            op: data["operation".concat(key.substr(-1))],
            value: data["value".concat(key.substr(-1))],
            model_name: data["model".concat(key.substr(-1))],
            parent_model: data["parentModel".concat(key.substr(-1))],
            join_op:
              data["condition".concat(key.substr(-1))] == undefined
                ? null
                : data["condition".concat(key.substr(-1))],
          });
        } else {
          return;
        }
      }
    });

    return obj;
  };

  handleShowDetailsTable = (e, obj, key) => {
    e.preventDefault();
    this.setState({ showDetailsTable: !this.state.showDetailsTable });
    if (obj) {
      this.setState({ members: obj, detailsTitle: key });
    }
  };

  showAttribute = () => {
    if (this.state.columnlist.length == 0) {
      let selectedColumnsList = this.getFilter(this.state.data);
      selectedColumnsList.map((item) => {
        this.state.columnlist.push({
          field: item.attribute,
          model_name: item.model_name,
          display_name: item.display_name,
        });
      });
      this.setState({
        showAtrbt: !this.state.showAtrbt,
        columnlist: this.state.columnlist,
        parameters: this.state.parameters.concat(this.state.columnlist),
      });
    } else {
      this.setState({
        showAtrbt: !this.state.showAtrbt,
      });
    }
  };

  closeAttri = () => {
    this.setState({
      showAtrbt: !this.state.showAtrbt,
    });
  };

  handleShowTable = () => {
    this.setState({
      showTable: true,
    });
  };

  handleResponseHeader() {
    let advancefilterinfo = this.state.data.layer.advancefilterinfo;

    let properties = {};
    let names = {};
    this.state.result.map((res, i) => {
      properties = res;
    });
    advancefilterinfo &&
      advancefilterinfo.map((res, i) => {
        names = res;
      });
    if (properties != null) {
      return (
        <tr key={-1}>
          <th>SL NO</th>
          {Object.keys(properties).map((key) => {
            console.log("key values", properties[key]);
            if (key == "Member_Details") {
              return <th key={key}>MEMBER COUNT</th>;
            } else if (key == "Owner_Details") {
              return <th key={key}>OWNER DETAILS</th>;
            } else if (!key.includes("photo") && !key.includes("p_d_f_") ) {
              return <th key={key}>{getAttributeName(key)}</th>;
            }
          })}
        </tr>
      );
    }
  }

  handleResponseMemberHeader() {
    let advancefilterinfo = this.state.data.layer.advancefilterinfo;

    let properties = {};
    let names = {};
    this.state.memberData &&
      this.state.memberData.map((res, i) => {
        properties = res;
      });
    advancefilterinfo.map((res, i) => {
      names = res;
    });
    if (properties != null) {
      return (
        <tr key={-1}>
          <th>SL NO</th>
          {Object.keys(properties).map((key) => {
            if (key == "Member_Details") {
              return <th key={key}>MEMBER COUNT</th>;
            } else if (key == "Owner_Details") {
              return <th key={key}>OWNER DETAILS</th>;
            } else if (!key.includes("photo") && !key.includes("p_d_f_")) {
              return <th key={key}>{getAttributeName(key)}</th>;
            }
          })}
        </tr>
      );
    }
  }

  handleResponse() {
    let properties = {};
    return this.state.result.map((res, i) => {
      properties = res;

      if (properties != null) {
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            {Object.keys(properties).map((key) => {
              var key1, floorPropArea, propRoofType;
              if (!key.includes("photo") && !key.includes("p_d_f_")) {
                switch (key) {
                  case "Member_Details": {
                    var obj = [];
                    if (properties["Member_Details"] != "") {
                      obj = JSON.parse(properties["Member_Details"]);
                      key1 = "Member Details";

                      return (
                        <td key={key}>
                          <button>{obj.length}</button>
                        </td>
                      );
                    } else return <td key={key}></td>;
                    break;
                  }
                  case "Owner_Details": {
                    if (properties["Owner_Details"] != "") {
                      var obj = JSON.parse(properties["Owner_Details"]);
                      var ownerDisplay = "";

                      obj.map((ownerItem) => {
                        ownerDisplay += ownerItem["firstname"]
                          ? ownerItem["firstname"]
                          : null;
                      });
                      key1 = "Owner Details";
                      return (
                        <td key={key}>
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="menu">
                                Click on to see more details
                              </Tooltip>
                            }
                          >
                            <p
                              onClick={(e) =>
                                this.handleShowDetailsTable(e, obj, key1)
                              }
                            >
                              {ownerDisplay}
                            </p>
                          </OverlayTrigger>
                        </td>
                      );
                    } else return <td key={key}></td>;
                    break;
                  }
                  case "Floor_Prop_Area": {
                    var floorDisplay = "";
                    const newline = "<br>";
                    if (properties["Floor_Prop_Area"] != "") {
                      floorPropArea = JSON.parse(properties["Floor_Prop_Area"]);

                      floorPropArea.map((floorItem) => {
                        if (floorItem["floor_category"])
                          floorDisplay +=
                            floorItem["floor_category"] +
                            ":" +
                            floorItem["floor_area"] +
                            ";  ";
                        else if (floorItem["floor_area"]) {
                          floorDisplay += floorItem["floor_area"] + ";";
                        }
                        // floorDisplay += floorItem["floor_category"]
                        //   ? floorItem["floor_category"] +
                        //     ":" +
                        //     floorItem["floor_area"] +
                        //     ";  "
                        //   : floorItem["floor_area"] + ";";
                      });
                    }
                    return <td key={key}>{floorDisplay}</td>;
                    break;
                  }
                  case "Property_Roof_Type": {
                    var roofDisplay = "";
                    if (properties["Property_Roof_Type"] != "") {
                      propRoofType = JSON.parse(
                        properties["Property_Roof_Type"]
                      );

                      propRoofType.map((roofItem) => {
                        roofDisplay +=
                          roofItem["roof_type"] +
                          ":" +
                          roofItem["roof_per"] +
                          "%;  ";
                      });
                    }
                    return <td key={key}>{roofDisplay}</td>;
                    break;
                  }

                  default: {
                    return (
                      <td className="new-line" key={key}>
                        {properties[key] ? properties[key].toString() : "Null"}
                      </td>
                    );
                  }
                }
              }
            })}
          </tr>
        );
      }
    });
  }

  handleMemberResponse() {
    let properties = {};
    return (
      this.state.memberData &&
      this.state.memberData.map((res, i) => {
        properties = res;
        if (properties != null) {
          return (
            <tr key={i}>
              <td>{i + 1}</td>
              {Object.keys(properties).map((key) => {
                var key1, floorPropArea, propRoofType;
                if (!key.includes("photo") && !key.includes("p_d_f_")) {
                  // return <p>hhh</p>;

                  switch (key) {
                    case "member": {
                      var obj = [];
                      if (properties["member"] != "") {
                        return <td key={key}></td>;
                      } else return <td key={key}></td>;
                      break;
                    }
                    case "Owner_Details": {
                      if (properties["Owner_Details"] != "") {
                        var obj = JSON.parse(properties["Owner_Details"]);
                        var ownerDisplay = "";

                        obj.map((ownerItem) => {
                          ownerDisplay += ownerItem["firstname"];
                        });
                        key1 = "Owner Details";
                        return (
                          <td key={key}>
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id="menu">
                                  Click on to see more details
                                </Tooltip>
                              }
                            >
                              <p
                                onClick={(e) =>
                                  this.handleShowDetailsTable(e, obj, key1)
                                }
                              >
                                {ownerDisplay}
                              </p>
                            </OverlayTrigger>
                          </td>
                        );
                      } else return <td key={key}></td>;
                      break;
                    }
                    case "Floor_Prop_Area": {
                      var floorDisplay = "";
                      const newline = "<br>";
                      if (properties["Floor_Prop_Area"] != "") {
                        floorPropArea = JSON.parse(
                          properties["Floor_Prop_Area"]
                        );
                        floorPropArea.map((floorItem) => {
                          floorDisplay +=
                            floorItem["floor_category"] +
                            ":" +
                            floorItem["floor_area"] +
                            ";  ";
                        });
                      }
                      return <td key={key}>{floorDisplay}</td>;
                      break;
                    }
                    case "Property_Roof_Type": {
                      var roofDisplay = "";
                      if (properties["Property_Roof_Type"] != "") {
                        propRoofType = JSON.parse(
                          properties["Property_Roof_Type"]
                        );
                        propRoofType.map((roofItem) => {
                          roofDisplay +=
                            roofItem["roof_type"] +
                            ":" +
                            roofItem["roof_per"] +
                            "%;  ";
                        });
                      }
                      return <td key={key}>{roofDisplay}</td>;
                      break;
                    }
                    default: {
                      return (
                        <td className="new-line" key={key}>
                          {properties[key]}
                        </td>
                      );
                    }
                  }
                }
              })}
            </tr>
          );
        }
      })
    );
  }

  handleBackClick = () => {
    this.props.history.push("/map");
  };

  handleInputChangeLayer = (event) => {
    this.props.layers.map((layer) => {
      if (layer.layer_id == event.target.value) {
        this.setState(
          {
            data: {
              ...this.state.data,
              layer: layer,
              reportName: layer.label,
              layerName: layer.label,
            },

            filterFormCount: [],
            index: 0,
            modelList: [],
            columnlist: [],
            parameters: [],
            saveTemplateFileName: "",
          },
          () => {
            this.deleteAllFeilds();
          }
        );
      }
    });
  };

  clearSearch = (event) => {
    this.setState(
      {
        data: {
          layer: "",
          layerCategory: "",
          reportName: "",
          display_name: "",
          fileFormat: "",
          layerName: "",
          fileType: "",
        },
      },
      () => {
        this.deleteAllFeilds();
      }
    );

    this.setState({ isDownloading: false });
  };

  //USED IN ATRIBUTE COMPONENT
  handleInputChangeSelect = (event, int) => {
    const attributeInformation = this.state.data.layer.advancefilterinfo.find(
      (attribute) => attribute.field === event.target.value
    );
    console.log("attributeInformation", attributeInformation);
    console.log("log", this.state.data.layer.advancefilterinfo);
    console.log("target log", event.target.value);
    
    

    let newColumns = {
      field: attributeInformation.field,
      model_name: attributeInformation.model_name,
      display_name: event.target.value,
    };

    let flag = true;
    if (this.state.parameters.length != 0) {
      // Uncheck the selected attribute
      this.state.parameters.map((item, index) => {
        if (item.display_name == event.target.value) {
          flag = false;
          var array = [...this.state.parameters];
          // make a separate copy of the array
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ parameters: array }, () => {});
          }
        }
      });
    }
    if (flag) {
      this.setState(
        {
          parameters: [...this.state.parameters, newColumns],
        },
        () => {}
      );
    }
  };

  handleInputSelectAll = () => {
    let array = [];
    this.state.data.layer.advancefilterinfo.map((item) => {
      let newColumns = {
        field: item.field,
        model_name: item.model_name,
        display_name: item.display_name,
      };
      array.push(newColumns);
    });

    this.setState(
      {
        parameters: array,
      },
      () => {}
    );
  };
  //unselect all functionality
  handleInputUnselectAll = () => {
    this.setState(
      {
        parameters: [],
      },
      () => {}
    );
  };

  handleSubmit = (e) => {
    this.setState({
      percentage: 0,
      page: 1,
      isDownloading: false,
    });
    e.persist();

    e.preventDefault();
    e.stopPropagation();

    if (!this.state.showSubmitSelect) {
      this.handleIsNoFilter();
    } else {
      let parentModel = this.state.data.layer.advancefilterinfo.filter(
        (attribute) => {
          return attribute.parent_model;
        }
      );

      // To get model names of selected columns in a list
      // List should contain the parent model name as first element
      let parentModelList = {};
      parentModelList = [parentModel[0].parent_model];
      let modelNames = {};
      modelNames = this.state.parameters.map((model) => {
        return model.model_name;
      });
      const finalModelList = [...parentModelList, ...modelNames];
      // To eliminate duplicate elements
      let modelList = [...new Set(finalModelList)];

      // logic: Id field of the parent model must be send along with the selected columns
      let idColumn = {
        field: "id",
        model_name: parentModel[0].parent_model,
        display_name: "ID Field",
      };
      this.state.parameters.push(idColumn);

      // Data to be send in request
      let filter = {
        page: "1",
        limit: this.state.limit,
        report: "False",
        file_name: this.state.fileName,
        file_extension: "",
        models: modelList,
        columns: this.state.parameters,
        layer_name: this.state.data.layerName,
        parent_model: parentModel[0].parent_model,
        obj: this.getFilter(this.state.data),
        category_field: this.state.data.layer.category_field,
        category_id: this.state.data.layer.category_id,
      };
      this.setState({
        parentModel: parentModel[0].parent_model,
        modelList: modelList,
      });
      this.props.fetchDataInReport(filter);

      this.setState({
        resultParams: filter,
      });
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////

  handleVerification = (value) => {
    this.props.history.push(`/DataCollection`, { model: value });
  };
  ///////////////////////////////////////////////////////////////////////////////////////

  downloadFile = () => {
    // if (this.downloadLoading) return;
    this.setState({ isDownloading: false });
    let urls =
      this.props.reportUrls &&
      this.props.reportUrls[0] &&
      this.props.reportUrls[0].urls;

    if (!urls) return;
    if (this.state.data.fileFormat === "csv") {
      const { building, member, merged } = urls.csv;
      if (this.state.data.layerName === "Residential") {
        this.state.data.fileType === "building" && window.open(building);
        this.state.data.fileType === "member" && member && window.open(member);
        this.state.data.fileType === "merged" && merged && window.open(merged);
      } else {
        building && window.open(building);
      }
    }
    if (this.state.data.fileFormat === "excel") {
      const { building, member, merged } = urls.excel;
      if (this.state.data.layerName === "Residential") {
        this.state.data.fileType === "building" && window.open(building);
        this.state.data.fileType === "member" && member && window.open(member);
        this.state.data.fileType === "merged" && merged && window.open(merged);
      } else {
        building && window.open(building);
      }
    }
  };

  generateReport = () => {
    this.props.recordCount > 1000 &&
      this.setState({ downloadConfirmation: true });
    this.setState({ downloadPercentage: 0, isDownloading: true });

    let reportValues = {
      page: 1,
      limit: this.state.downloadLimit,
      report: "True",
      // file_name: this.state.data.reportName,
      file_name: this.state.fileName,
      file_extension: this.state.data.fileFormat,
      models: this.state.modelList,
      columns: this.state.parameters,
      layer_name: this.state.data.layerName,
      parent_model: this.state.parentModel,
      obj: this.getFilter(this.state.data),
      category_field: this.state.data.layer.category_field,
      category_id: this.state.data.layer.category_id,
      unique_id: this.props.unique_id,
      recordCount: this.props.recordCount,
      maxPage: this.props.recordCount / this.state.downloadLimit,
      file_type: this.state.data.fileType ? this.state.data.fileType : null,
    };
    this.props.downloadReport(reportValues);
    this.setState({ downloadPage: 1 });
    // window.open(this.props.reportLink);
  };
  appendDownload = () => {
    try {
      if (!this.state.isDownloading) return;

      let maxCount = this.props.recordCount / this.state.downloadLimit;
      let pusherCount = (100 / maxCount).toFixed(2);
      let currentPage = Number(this.state.downloadPage);
      let currentPusherCount = Number(this.state.downloadPercentage);
      if (maxCount > currentPage) {
        let reportValues = {
          page: currentPage + 1,
          limit: this.state.downloadLimit,
          report: "True",
          // file_name: this.state.data.reportName,
          file_name: this.state.fileName,
          file_extension: this.state.data.fileFormat,
          models: this.state.modelList,
          columns: this.state.parameters,
          layer_name: this.state.data.layerName,
          parent_model: this.state.parentModel,
          obj: this.getFilter(this.state.data),
          category_field: this.state.data.layer.category_field,
          category_id: this.state.data.layer.category_id,
          unique_id: this.props.unique_id,
          recordCount: this.props.recordCount,
          maxPage: this.props.recordCount / this.state.downloadLimit,
          file_type: this.state.data.fileType ? this.state.data.fileType : null,
        };
        this.props.downloadReport(reportValues);
        this.setState({ downloadPage: currentPage + 1 });

        // let pusherCount = 100 / maxCount;

        var sumPercentage = Number(currentPusherCount) + Number(pusherCount);
        let finalPercentage = sumPercentage.toFixed(2);
        if (sumPercentage < 100) {
          this.setState({
            downloadPercentage: finalPercentage,
          });
        }
      } else {
        !this.props.downloadLoading &&
          this.props.reportFile &&
          this.setState({ readyToDownloadFlag: true, isDownloading: false });
      }
    } catch (e) {
      return;
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tableData != this.props.tableData) {
      if (this.props.recordCount > 0) {
        this.setState(
          {
            result: this.props.tableData,
            layer: this.state.data.layer,
            reportStatus: true,
            memberData: this.props.memberData,
          },
          () => {
            this.handleShowTable();
          }
        );
      } else {
        this.setState({
          showTable: false,
        });
        this.props.showSnackbar("No Features Found");
      }
    }
    if (prevState.page != this.state.page) {
      // Data to be send in request
      let filter = {
        page: this.state.page,
        limit: this.state.limit,
        report: "False",
        file_name: "",
        file_extension: "",
        models: this.state.modelList,
        columns: this.state.parameters,
        layer_name: this.state.data.layerName,
        parent_model: this.state.parentModel,
        obj: this.getFilter(this.state.data),
        category_field: this.state.data.layer.category_field,
        category_id: this.state.data.layer.category_id,
      };
      this.setState({ percentage: 0 });
      this.props.fetchDataInReport(filter);
    }
    if (prevProps.report_result != this.props.report_result) {
      if (this.props.report_result == "success") {
        let reportValues = {
          page: this.state.page,
          limit: this.props.recordCount,
          report: "True",
          file_name: this.state.data.reportName,
          file_extension: this.state.data.fileFormat,
          models: this.state.modelList,
          columns: this.state.parameters,
          layer_name: this.state.data.reportName,
          parent_model: this.state.parentModel,
          obj: this.getFilter(this.state.data),
        };
        this.props.getReportLink(reportValues);
      }
    }
    if (prevProps.reportLink != this.props.reportLink) {
      window.open(this.props.reportLink);
      if (this.props.memberLink != undefined)
        window.open(this.props.memberLink);
    }
    //download file functionality
    if (prevProps.reportFile != this.props.reportFile) {
      this.setState({ downloadConfirmation: false });
    }

    if (prevProps.downlaodPage != this.props.downlaodPage) {
      this.appendDownload();
    }
    if (prevProps.isSave != this.props.isSave) {
      this.props.isSave && this.notify("Template saved successfully");
    }

    if (prevProps.isDeleted != this.props.isDeleted) {
      this.props.isDeleted && this.notify("Template deleted successfully");
    }
  }

  afterPageClicked = (page) => {
    if (!isNaN(page))
      this.setState({
        page: page,
      });
  };

  handleClose = () => {
    this.setState({ showConfirmation: false });
  };

  handleInputChangeName = (e) => {
    this.setState({ fileName: e.target.value });
  };
  clearLayer = () => {
    let temData = this.state.data;

    if (temData.layer && temData.layer.layer_id) {
      temData.layer.layer_id = "";
      this.setState({ data: temData });
    }
  };
  //saved template save functionality

  onSaveTemplate = (el) => {
    this.setState({ isShowSaveTemplateSave: false });
    let localBody;
    if (localStorageHandler.getLocalBody() !== null) {
      localBody = localStorageHandler.getLocalBody().local_body_id;
    }

    let data = this.state.layerCategorys.find(
      (el) => el.layer_category_id == this.state.data.layerCategory
    );

    let fieldValue = this.state.data;
    fieldValue.filterFormCount = this.state.filterFormCount;
    fieldValue.parameters = this.state.parameters;
    fieldValue.downloadFileName = this.state.fileName;
    fieldValue.layerLabel = data.label;
    this.props.saveReportTemplate({
      query: this.state.resultParams,
      field_values: fieldValue,
      file_name: this.state.saveTemplateFileName,
      uid: localBody,
    });
  };

  //saved template get functionality
  getTemplate = () => {
    this.setState({ showTemplate: true });
    let localBody;
    if (localStorageHandler.getLocalBody() !== null) {
      localBody = localStorageHandler.getLocalBody().local_body_id;
    }
    this.props.getReportTemplate({
      uid: localBody,
    });

    // this.props.getReportTemplate()
  };

  getResult = (values) => {
    this.setState({
      data: values.field_values,
      showTemplate: false,
      saveTemplateFileName: values.file_name,
      parameters: values.field_values.parameters
        ? values.field_values.parameters
        : [],
      fileName: values.field_values.downloadFileName
        ? values.field_values.downloadFileName
        : "",
    });
    if (values.field_values.filterFormCount) {
      this.setState(
        { filterFormCount: values.field_values.filterFormCount },
        () => {
          this.filterCheck();
          this.props.clearFilterInitAction();
        }
      );
    }
  };

  handleInputTemplateChangeName = (el) => {
    this.setState({ saveTemplateFileName: el.target.value });
  };

  deleteTemplate = (index, el) => {
    let savedTemplate = this.props.savedTemplate;
    savedTemplate.splice(index, 1);
    this.props.deleteReportTemplate({ savedTemplate: savedTemplate, data: el });
    this.setState({ showTemplate: false });
  };
  notify = (message) => toast(message);

  render() {
    console.log("state result  values", this.state.data);
    return (
      <div className="reportContainer">
        <ResultModel
          show={this.state.showDetailsTable}
          handleShowResult={this.handleShowDetailsTable}
          result={this.state.members}
          title={this.state.detailsTitle}
        />
        <ToastContainer />
        <SavedTemplate
          show={this.state.showTemplate}
          onHide={() => {
            this.setState({ showTemplate: false });
          }}
          savedTemplate={this.props.savedTemplate}
          getResult={(values) => this.getResult(values)}
          deleteTemplate={this.deleteTemplate}
          fetching={this.props.fetching}
        />

        <React.Fragment>
          <div className="reportTitle">
            <Row>
              <img
                src={backArrow}
                className="back"
                onClick={this.handleBackClick}
              />
              IPMS
              {/* <div style={{}}> */}
              <button
                style={{ float: "right", marginRight: 30 }}
                type="button"
                className="btn btn-success"
                onClick={() => this.handleVerification("VerificationData")}
              >
                Verification Form Downloader
              </button>
              <button
                style={{ float: "right", marginRight: 60 }}
                type="button"
                className="btn btn-success"
                onClick={this.getTemplate}
              >
                Saved Templates
              </button>
              {/* </div> */}
            </Row>
          </div>

          <Loader />
          <div className="reportCard">
            <div className="selectLayerRow">
              <Row>
                <Col xs={12} sm={4} md={3} lg={3}>
                  <FormGroup className="selectLayer">
                    <ControlLabel style={{ fontWeight: "bold" }}>
                      Layer Category
                    </ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="layerCategory"
                      // className="drp_layer"
                      value={this.state.data.layerCategory}
                      onChange={(ev) => {
                        this.handleInputChange(ev, "layer");
                      }}
                      required
                    >
                      <option value="">Select Layer Category</option>
                      {this.state.layerCategorys
                        .sort((a, b) => (a["label"] > b["label"] ? 1 : -1))
                        .map((layerCategory) => {
                          return (
                            <option
                              key={layerCategory.value}
                              value={layerCategory.layer_category_id}
                            >
                              {layerCategory.label}
                            </option>
                          );
                        })}
                    </FormControl>
                  </FormGroup>
                </Col>

                <Col xs={12} sm={4} md={3} lg={3}>
                  {" "}
                  <FormGroup className="selectLayer">
                    <ControlLabel style={{ fontWeight: "bold" }}>
                      Layer
                    </ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="layer"
                      value={this.state.data.layer.layer_id}
                      disabled={this.state.data.layerCategory == ""}
                      onChange={this.handleInputChangeLayer}
                      required
                    >
                      <option value="">Select layer</option>
                      {this.props.layers
                        .sort((a, b) => (a["label"] > b["label"] ? 1 : -1))
                        .map((layer) => {
                          // if (layer.adv_fltr_status) {
                          if (
                            layer.layer_category_id ==
                            this.state.data.layerCategory
                          ) {
                            return (
                              <option key={layer.value} value={layer.layer_id}>
                                {layer.label}
                              </option>
                            );
                          }
                          // }
                        })}
                    </FormControl>
                  </FormGroup>
                </Col>

                {/* <Col xs={12} sm={3} md={3} lg={3}>
                  <FormGroup required>
                    <ControlLabel style={{ fontWeight: "bold" }}>
                      Records per page
                    </ControlLabel>
                    <FormControl
                      type="input"
                      placeholder="Enter records per page"
                      value={this.state.limit}
                      name="limit"
                      onChange={this.handleInputChangeLimit}
                      required
                    />
                  </FormGroup>
                </Col> */}

                <Col xs={12} sm={3} md={3} lg={3}>
                  <FormGroup required>
                    <ControlLabel style={{ fontWeight: "bold" }}>
                      File name
                    </ControlLabel>
                    <FormControl
                      type="input"
                      placeholder="Enter file name"
                      value={this.state.fileName}
                      name="limit"
                      onChange={this.handleInputChangeName}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={2} md={2} lg={2}>
                  <FormGroup>
                    <Button
                      bsSize="xsmall"
                      block
                      className="btn_sel_col"
                      onClick={() => this.addFilterForm("Add")}
                      disabled={this.state.data.layer === ""}
                      style={{ marginTop: 20 }}
                    >
                      <i className="glyphicon glyphicon-plus-sign" /> Add Filter
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <Button
                    bsSize="xsmall"
                    type="submit"
                    block
                    disabled={!this.state.data.layerCategory}
                    className="btn_sel_col"
                    onClick={this.showAttribute}
                  >
                    Select Columns To Display
                  </Button>
                </Col>

                <Col xs={12} sm={3} md={2} lg={2}>
                  <Button
                    bsSize="xsmall"
                    block
                    type="submit"
                    bsStyle="success"
                    disabled={
                      this.state.parameters.length == 0 ||
                      !this.state.data.layer.layer_id
                    }
                    className="btn_submit"
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </Button>
                </Col>

                {/* //clear search */}

                <Col xs={12} sm={3} md={2} lg={2}>
                  <Button
                    block
                    type="submit"
                    disabled={this.props.recordCount === 0}
                    className="btn_submit"
                    onClick={() => {
                      this.props.clearFilterInitAction();
                      this.clearSearch();
                    }}
                  >
                    Clear Search
                  </Button>
                </Col>

                <Col xs={12} sm={3} md={2} lg={2}>
                  <Button
                    block
                    type="submit"
                    disabled={this.props.recordCount === 0}
                    className="btn_submit"
                    onClick={() => {
                      this.setState({ isShowSaveTemplateSave: true });
                    }}
                  >
                    Save Template
                  </Button>
                </Col>

                {/* ////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                {/* <Col xs={12} sm={3} md={2} lg={2}>
                  <Button
                    block
                    type="submit"
                    disabled={this.props.recordCount === 0}
                    className="btn_submit"
                    onClick={() => this.handleVerification("VerificationData")}
                  >
                    Verification Data
                  </Button>
                </Col> */}

                {/* ////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <ConfirmBox
                  massage={
                    "You are selected a huge data, So it will take time..!!!"
                  }
                  show={this.state.showConfirmation}
                  action={() => {
                    this.setState({ showConfirmation: false });
                  }}
                  onHide={this.handleClose}
                  isNotSelected={true}
                />

                <ConfirmBox
                  massage={
                    "You are downloading a huge data, So it will take time..!!!"
                  }
                  show={this.state.downloadConfirmation}
                  action={() => {
                    this.setState({ downloadConfirmation: false });
                  }}
                  onHide={() => {
                    this.setState({ downloadConfirmation: false });
                  }}
                  isNotSelected={true}
                />

                <ConfirmBox
                  massage={"Your file is ready to download"}
                  show={this.state.readyToDownloadFlag}
                  action={() => {
                    this.setState({ readyToDownloadFlag: false });
                  }}
                  onHide={() => {
                    this.setState({ readyToDownloadFlag: false });
                  }}
                  isNotSelected={true}
                />

                <InputBoxModal
                  massage={"Your file is ready to download"}
                  show={this.state.isShowSaveTemplateSave}
                  action={(el) => {
                    // this.setState({ isShowSaveTemplateSave: false });
                    this.onSaveTemplate(el);
                  }}
                  onHide={() => {
                    this.setState({ isShowSaveTemplateSave: false });
                  }}
                  isNotSelected={true}
                  saveTemplateFileName={this.state.saveTemplateFileName}
                  handleInputChangeName={this.handleInputTemplateChangeName}
                />

                <Filter
                  data={this.state.data}
                  filterCheck={this.filterCheck}
                  addFilterForm={this.addFilterForm}
                  clearFilter={this.clearFilter}
                  handleInputChange={this.handleInputChange}
                  handleInputChangeDropdown={this.handleInputChangeDropdown}
                  filterFormCount={this.state.filterFormCount}
                  handleToUpdate={this.handleToUpdate}
                  handleInputChangeAttribute={this.handleInputChangeAttribute}
                  handleInputChangeOperation={this.handleInputChangeOperation}
                  temp={temp}
                  deleteField={this.deleteField}
                  handleClearValue={this.handleClearValue}
                />
                {this.state.data.layer != "" && (
                  <Attribute
                    data={this.state.data}
                    columnStatus={this.state.columnStatus}
                    show={this.state.showAtrbt}
                    showAttri={this.showAttribute}
                    closeAttri={this.closeAttri}
                    parameters={this.state.parameters}
                    handleInputChange={this.handleInputChange}
                    handleInputChangeSelect={this.handleInputChangeSelect}
                    handleInputSelectAll={this.handleInputSelectAll}
                    handleInputUnselectAll={this.handleInputUnselectAll}
                  />
                )}
              </Row>
            </div>

            {this.props.fetchReportLoading && (
              <div className="resultProgressBarDiv">
                <div className="resultProgressBar">
                  <CircularProgressbar
                    value={this.state.percentage}
                    text={`${this.state.percentage}%`}
                  />
                </div>
              </div>
            )}

            {!this.props.fetchReportLoading && this.props.recordCount === 0 && (
              <div>
                <img src={NoDataImg} width="100" className="center" />
                <p className="noDataTxt">No data found</p>
              </div>
            )}
            <Row>
              {this.state.showTable && (
                <React.Fragment>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="resultContainerModel">
                      <Table
                        striped
                        bordered
                        condensed
                        hover
                        className="detailedInfoContent"
                        id="table-to-xls"
                      >
                        <thead>{this.handleResponseHeader()}</thead>
                        <tbody>{this.handleResponse()}</tbody>
                      </Table>
                    </div>
                  </Col>
                  <TablePagination
                    recordCount={this.props.recordCount}
                    page={this.state.page}
                    limit={this.state.limit}
                    pageClicked={(item) => {
                      this.afterPageClicked(item);
                    }}
                  />
                  {/* {this.state.data.layerName === "Residential" && (
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div className="resultContainerModel">
                        <Table
                          striped
                          bordered
                          condensed
                          hover
                          className="detailedInfoContent"
                          id="table-to-xls"
                        >
                          <thead>{this.handleResponseMemberHeader()}</thead>
                          <tbody>{this.handleMemberResponse()}</tbody>
                        </Table>
                      </div>
                    </Col>
                  )} */}
                </React.Fragment>
              )}
            </Row>

            <div className="selectLayerRow">
              {this.state.showTable && (
                <React.Fragment>
                  <Row style={{ marginTop: "10px" }}>
                    <Col xs={12} sm={4} md={4} lg={4}>
                      {" "}
                      <FormGroup className="selectLayer">
                        <ControlLabel style={{ fontWeight: "bold" }}>
                          File Format
                        </ControlLabel>
                        <FormControl
                          componentClass="select"
                          placeholder="select"
                          name="fileFormat"
                          value={this.state.data.fileFormat}
                          onChange={this.handleInputChange}
                          required
                        >
                          <option value="">Select</option>
                          <option value="excel">Excel</option>
                          <option value="csv">CSV</option>
                        </FormControl>
                      </FormGroup>
                    </Col>
                    {this.state.data.layerName === "Residential" && (
                      <Col xs={12} sm={4} md={4} lg={4}>
                        {" "}
                        <FormGroup className="selectLayer">
                          <ControlLabel style={{ fontWeight: "bold" }}>
                            File
                          </ControlLabel>

                          <FormControl
                            componentClass="select"
                            placeholder="select"
                            name="fileFormat"
                            value={this.state.data.fileType}
                            onChange={(event) => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  fileType: event.target.value,
                                },
                              });
                              this.props.clearDownLoadInitAction();
                            }}
                            required
                          >
                            <option value="">Select</option>
                            <option value="building">
                              Residential details
                            </option>
                            member
                            {this.props.reportUrls &&
                              this.props.reportUrls[0] &&
                              this.props.reportUrls[0].urls.csv.member && (
                                <option value="member">Member details</option>
                              )}
                            {this.props.reportUrls &&
                              this.props.reportUrls[0] &&
                              this.props.reportUrls[0].urls.csv.merged && (
                                <option value="merged">
                                  Merged building and member details
                                </option>
                              )}
                          </FormControl>
                        </FormGroup>
                      </Col>
                    )}
                    <Col xs={6} sm={3} md={2} lg={2}>
                      <Button
                        bsSize="xsmall"
                        block
                        type="submit"
                        bsStyle="success"
                        className="btn_submit"
                        onClick={
                          this.props.reportFile
                            ? this.downloadFile
                            : this.generateReport
                        }
                        disabled={
                          this.state.data.layerName === "Residential"
                            ? this.state.data.fileFormat == "" ||
                              this.state.data.fileType == ""
                            : this.state.data.fileFormat == ""
                        }
                      >
                        {this.props.reportFile ? "Download" : "Export"}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {this.props.downloadLoading && (
                        <div className="resultProgressBarDiv">
                          <div className="resultProgressBar">
                            <CircularProgressbar
                              value={this.state.downloadPercentage}
                              text={`${this.state.downloadPercentage}%`}
                            />
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <h5 style={{ padding: "0px", marginTop: "34px" }}>
                        Total/Searched Count: {this.props.recordCount}
                        <Badge className="countBadge" />
                      </h5>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    show: state.mapSearch.showAdvancedFilterWindow,
    layerColumns: state.advancedFilter.layerColumns,
    layers: state.advancedFilter.layers,
    tableData: state.advancedFilter.tableData,
    recordCount: state.advancedFilter.recordCount,
    fetching: state.advancedFilter.fetching,
    data: state.advancedFilter.data,
    report_result: state.advancedFilter.report_result,
    reportLink: state.advancedFilter.reportLink,
    memberLink: state.advancedFilter.memberLink,
    fetchReportLoading: state.advancedFilter.fetchReportLoading,
    memberData: state.advancedFilter.memberData,
    reportUrls: state.advancedFilter.reportUrls,
    reportFile: state.advancedFilter.reportFile,
    downloadLoading: state.advancedFilter.downloadLoading,
    unique_id: state.advancedFilter.unique_id,
    downlaodPage: state.advancedFilter.page,
    savedTemplate: state.advancedFilter.savedTemplate,
    isSave: state.advancedFilter.isSave,
    isDeleted: state.advancedFilter.isDeleted,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPageHeader: (title, subtitle, action) =>
      dispatch(setPageHeader(title, subtitle, action)),
    fetchColumnsByLayer: (layer) => dispatch(fetchColumnsByLayer(layer)),
    hide: () => dispatch(toggleAdvancedFilterWindow()),
    isLoading: () => dispatch(loadingActions.isloading()),
    loadingComplete: () => dispatch(loadingActions.loadingComplete()),
    getLayers: () => dispatch(getLayers()),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),

    fetchDataInReport: (filterValues) =>
      dispatch(fetchDataInReport(filterValues)),
    generateReport: (reportValues) => dispatch(generateReport(reportValues)),
    getReportLink: (reportValues) => dispatch(getReportLink(reportValues)),
    requestSearchedLayer: (layerToSearch, featureRequest) =>
      dispatch(requestSearchedLayer(layerToSearch, featureRequest)),

    downloadReport: (reportValues) => dispatch(downloadReport(reportValues)),

    clearFilterInitAction: () => dispatch(clearFilterInitAction()),
    clearDownLoadInitAction: () => dispatch(clearDownLoadInitAction()),

    saveReportTemplate: (payload) => dispatch(saveReportTemplate(payload)),
    getReportTemplate: (payload) => dispatch(getReportTemplate(payload)),
    deleteReportTemplate: (payload) => dispatch(deleteReportTemplate(payload)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Report));
