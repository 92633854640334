import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Row, Col, Tab, Tabs, Panel } from "react-bootstrap";
import "../style.css";
import DashboardLayout from "../DashboardLayout";
import {
  getDashboardFilters,
  getDashboardSummary,
  getWards,
} from "../data/action";
import MemberDetails from "./MemberDetails";
import { getData } from "../data";
import LocalStorageHandler from "../../../util/storage";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diagrams: null,
    };
  }

  componentDidMount() {
    const localBodyId = LocalStorageHandler.getLocalBody().local_body_id;
    this.fetchDashboardSummary(localBodyId);
    if (!this.props.isFiltersLoaded) this.props.getDashboardFilters();
    if (!this.props.isWardsLoaded)
      this.props.getWards(LocalStorageHandler.getLocalBody().local_body_id);
  }

  fetchDashboardSummary = async (i) => {
    try {
      // const localBodyId = LocalStorageHandler.getLocalBody().local_body_id;
      // console.log("locabody id",localBodyId);

      this.props.getDashboardSummary(i, 0);
    } catch (error) {
      console.error("Error fetching dashboard summary:", error);
    }
  };

  handleRedirect = (link) => {
    this.props.history.push(link);
  };

  render() {
    let diagrams = this.props?.dashboardData;
    console.log("diagrams", diagrams);

    return (
      <DashboardLayout handleRedirect={this.handleRedirect}>
        <Grid style={{ width: "100%" }}>
          <Row className="show-grid">
            {/* <Col xs={12} md={12} lg={12}> */}
            <Row className="socio-title">
              <Col xs={9} md={9} lg={9}>
                <h2 className="dashboard-heading">Socio Economic</h2>
              </Col>
              {/* <Col xs={6} md={6} lg={6}> */}
              {diagrams &&
                diagrams.counts.map(
                  (item, i) =>
                    item.title === "Total Population" && (
                      <Col key={i} xs={3} sm={3} md={3} lg={2}>
                        <Panel className="count-panel-population">
                          <Panel.Body style={{ padding: "5px" }}>
                            <p style={{ fontSize: "15px", margin: "0px" }}>
                              {item.title}
                            </p>
                            <p style={{ fontSize: "12px" }}>{item.value}</p>
                          </Panel.Body>
                        </Panel>
                      </Col>
                    )
                )}
            </Row>
            {/* </Col> */}
            <Col xs={12} md={12} lg={12}>
              <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                <Tab eventKey={1} title="Member Details">
                  <MemberDetails
                    downloadAction={this.props.downloadAction}
                    fetchDashboardSummary={this.fetchDashboardSummary}
                  />
                </Tab>
                {/* <Tab eventKey={2} title="Member Details">
                  Building details
                </Tab> */}
              </Tabs>
            </Col>
          </Row>
        </Grid>
      </DashboardLayout>
    );
  }
}

function mapStateToProps(state) {
  console.log("state", state);
  return {
    filters: state.dashboard.filters,
    isFiltersLoaded: state.dashboard.isFiltersLoaded,
    wards: state.dashboard.wards,
    isWardsLoaded: state.dashboard.isWardsLoaded,
    dashboardData: state.dashboard.dashboardSummary,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDashboardSummary: (localBodyId, wardId) =>
      dispatch(getDashboardSummary(localBodyId, wardId)),
    getWards: (localBodyId) => dispatch(getWards(localBodyId)),
    getDashboardFilters: () => dispatch(getDashboardFilters()),
    downloadAction: (data) => dispatch(downloadAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
