import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import "./Map.css";
import Map from "ol/Map.js";
import { XYZ, OSM } from "ol/source/";
import View from "ol/View.js";
import { defaults as defaultControls } from "ol/control.js";
import { Group as LayerGroup, Tile as TileLayer } from "ol/layer.js";
import { MousePosition, ScaleLine } from "ol/control/";
import { createStringXY } from "ol/coordinate.js";
import { transform } from "ol/proj.js";
import Draw from "ol/interaction/Draw.js";
import VectorSource from "ol/source/Vector.js";
import { GeoJSON, WFS } from "ol/format";
import Crop from "ol-ext/filter/Crop";
import generateLayer from "../helper/LayerLoader";
import getLayerTree from "../helper/LayerTreeLoader";
import LegendWidget from "../widgets/legend/LegendWidget";
import Opacity from "../widgets/opacity/OpacityWidget";
import LayerTree from "../widgets/layer_tree/LayerTree";
import StyleLoader from "../widgets/styleloader/StyleLoader";
import BaseMap from "../widgets/base_map/BaseMap";
import SelectByPolygon from "../widgets/select_by_polygon/SelectByPolygon";
import DefaultExtend from "../widgets/default_extend/DefaultExtend";
import FeatureInfo from "../widgets/feature_info/";
import MeasureAreaLength from "../widgets/measure/MeasureAreaLength";
import { updateMapReference } from "./data/actions";
import ExportPdf from "../widgets/export_pdf/ExportPdf";
import AddAsset from "../widgets/add_asset/AddAsset";
import GoToXY from "../widgets/goto_xy/GoToXY";
import Settings from "../widgets/usersettings/UserSettings";
import mapDefaults from "../helper/mapDefaults";
import GetCount from "../widgets/get_count/GetCount";
import CurrentLocation from "../widgets/current_location/CurrentLocation";
import Buffer from "../widgets/buffer/Buffer";
import { AUTH_KEY } from "../../../../config";
import LocalStorageHandler from "../../../../util/storage";
import SearchLayerPopup from "../widgets/searchPop/searchLayerPopup";

class OlMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLayerControl: false,
      layers: this.getLayersFromTree(),
      baseLayers: this.getBaseLayers(),
      clickEvent: null,
      vectorSource: null,
      addLayer: null,
      feature: false,
      vectorSource: null,
      showLayerControl: false,
      showLegendControl: false,
      showStyleControl: false,
      showBaseMapControl: false,
      showOpacityControl: false,
      showSettingsControl: false,
      userDetails: LocalStorageHandler.getUserDetails(),
      selectedCoordinate: null,
    };
    this.mapComponent = null;
  }

  async componentDidMount() {
    this.createOlMap();
    this.setState({
      vectorSource: new VectorSource(),
    });
  }
  setZoom() {
    var zoomlevel = mapDefaults.getDefaultZoom();
    if (this.mapComponent.getView().getZoom() <= zoomlevel) {
      this.mapComponent.getView().setZoom(zoomlevel + 2);
    } else {
      zoomlevel;
    }
  }

  handleMapRenderComplete = (evt) => {
    this.setState({ renderEvent: evt });
  };

  createCropFeature = async () => {
    let layerTree = getLayerTree();
    let cropLayers;
    let feature;
    layerTree.map((treeItem) => {
      
      if (treeItem.hasOwnProperty("children")) {
        treeItem.children.forEach((layer) => {
          if (layer.layer_name == "drishti:ipms_localbody") {
            cropLayers = layer;
          }
        });
      }
    });
    await axios
      .get(cropLayers && cropLayers.url.concat("wfs"), {
        params: {
          version: "1.3.0",
          request: "GetFeature",
          outputFormat: "application/json",
          service: "WFS",
          typeName: cropLayers && cropLayers.layer_name,
          srsname: "EPSG:3857",
          cql_filter: cropLayers && cropLayers.cql_filter,
          authkey: AUTH_KEY,
        },
      })
      .then((response) => {
        
        feature = new GeoJSON().readFeatures(response.data);
      
      });
      
    return feature;
  };

  getBaseLayers = () => {
    const osm = new TileLayer({
      name: "OSM",
      visible: true,
      isBaseLayer: true,
      source: new OSM({
        attributions: ["<b><a href='http://ults.in'>ULTS</a></b>"],
        crossOrigin: "anonymous",
      }),
    });
    const Satellite = new TileLayer({
      name: "Satellite",
      isBaseLayer: true,
      visible: false,
      source: new XYZ({
        url:
          "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiYWptYWxhdG0iLCJhIjoiY2tjNzFvbWNjMHg2ajJ2bWdlNzlmamU0MSJ9.z_qLRhyXbaBnJV4edvis-g",
        crossOrigin: "anonymous",
      }),
    });
    const googleSatellite = new TileLayer({
      name: "Google Satellite",
      isBaseLayer: true,
      visible: false,
      source: new XYZ({
        url: "http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
        crossOrigin: "anonymous",
      }),
    });
    const googleMap = new TileLayer({
      name: "Google Map",
      isBaseLayer: true,
      visible: false,
      source: new XYZ({
        url: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
        crossOrigin: "anonymous",
      }),
    });
    const googleHybrid = new TileLayer({
      name: "Google Hybrid",
      isBaseLayer: true,
      visible: true,
      source: new XYZ({
        url: "http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}",
        crossOrigin: "anonymous",
      }),
    });
    let baseLayerData = LocalStorageHandler.getBaseLayers();

    let baseLayers = [];
    baseLayers.push(osm);
    baseLayers.push(Satellite);
    baseLayers.push(googleMap);
    baseLayers.push(googleSatellite);
    baseLayers.push(googleHybrid);
    baseLayerData.map(async (layerItem) => {
      if (layerItem.layer_type == "base_layer") {
        let layer = generateLayer(
          layerItem.layer_type,
          layerItem.label,
          layerItem.layer_name,
          layerItem.type,
          layerItem.url,
          null,
          null,
          layerItem.visibility,
          null,
          layerItem.opacity,
          layerItem.crop
        );

        baseLayers.push(layer);
      }
    });
    return baseLayers;
  };

  getLayersFromTree() {
    let layerTree = getLayerTree();
    let layersFromTree = [];
    let mapLayers = [];
    layerTree.map((treeItem) => {
    
      if (treeItem.hasOwnProperty("children")) {
        treeItem.children.forEach((layer) => {
          if (layer.layer_type == "layer") {
            layersFromTree.push(layer);
          }
        });
      }
    });

    layersFromTree.forEach((treeLayerItem) => {
      
      let url = treeLayerItem.url;
      if (url != undefined) {
        let infoAttributes = {
          minimalInfoAttributes: treeLayerItem.minimalInfoAttributes,
          detailedInfoAttributes: treeLayerItem.detailedInfoAttributes,
        };
        let layer = generateLayer(
          treeLayerItem.layer_type,
          treeLayerItem.value,
          treeLayerItem.layer_name,
          treeLayerItem.type,
          treeLayerItem.url,
          treeLayerItem.style,
          treeLayerItem.cql_filter,
          false,
          infoAttributes,
          treeLayerItem.opacity,
          false
        );
     
        mapLayers.push(layer);
      }
    });
    return mapLayers;
    
  }

  createOlMap = () => {
    const { layers, baseLayers } = this.state;
    let localBody = null;
    if (LocalStorageHandler.getLocalBody() !== null) {
      localBody = LocalStorageHandler.getLocalBody().local_body_name;
    }
    this.mapComponent = new Map({
      controls: defaultControls({
        collapsible: false,
      }).extend([
        new ScaleLine(),
       
        new MousePosition({
          undefinedHTML: "",
          coordinateFormat: createStringXY(4),
          projection: "EPSG:4326",
        }),
      ]),
      layers: [
        new LayerGroup({ layers: baseLayers }),
        new LayerGroup({ layers }),
      ],
      target: "map",
      view: new View({
        center: transform(
          mapDefaults.getDefaultCenter(),
          "EPSG:4326",
          "EPSG:3857"
        ),
        zoom: mapDefaults.getDefaultZoom(),
        minZoom: mapDefaults.getDefaultZoom(),
      }),
    });

    this.mapComponent.on("singleclick", (evt) => this.handleMapClick(evt));

    this.mapComponent.on("pointermove", (evt) =>
      this.handleMapPointerMove(evt)
    );
    
    this.mapComponent.once("postcompose", async (evt) => {
      this.handleMapRenderComplete(evt);
      let cropFeature = await this.createCropFeature();
      baseLayers.forEach((layer) => {
        if (layer.get("crop") === true) {
          var crop = new Crop({
            feature: cropFeature[0],
            inner: false,
            active: true,
          });
          layer.addFilter(crop);
        }
      });
    });
    this.props.updateMapReference(this.mapComponent);
  };

  handleMapPointerMove = (evt) => {

    if (evt.dragging) return;
    try {
      var pixel = this.mapComponent.getEventPixel(evt.originalEvent);
      var hit = this.mapComponent.forEachLayerAtPixel(pixel, (layer) => {
        if (!layer.getProperties().isBaseLayer) return true;
      });
      this.mapComponent.getTargetElement().style.cursor = hit ? "pointer" : "";
    } catch (e) {
      console.log("Excepion", e);
    }
  };

  handleMapClick = (evt) => {
   
    let drawInteraction = this.mapComponent
      .getInteractions()
      .getArray()
      .filter((interaction) => {
        if (interaction instanceof Draw) return interaction;
      });
    if (drawInteraction.length > 0) return;
    if (!evt.dragging) 
    {
      const coordinates = evt.coordinate      
      console.log("mapclick coordinates",coordinates);
      
      this.setState({ clickEvent: evt,selectedCoordinate: transform(coordinates, 'EPSG:3857', 'EPSG:4326') });
    }
  };

  componentWillUnmount() {
    this.mapComponent.un("singleclick", this.handleMapClick);
    this.mapComponent.un("pointermove", this.handleMapPointerMove);
    this.mapComponent.setTarget(undefined);
  }

  handleShowLayerControl = () => {
    this.setState({
      showLegendControl: false,
      showBaseMapControl: false,
      showStyleControl: false,
      showOpacityControl: false,
      showLayerControl: !this.state.showLayerControl,
    });
  };
  handleShowStyleControl = () => {
    this.setState({
      showLayerControl: false,
      showLegendControl: false,
      showBaseMapControl: false,
      showOpacityControl: false,
      showStyleControl: !this.state.showStyleControl,
    });
  };
  handleShowLegendControl = () => {
    this.setState({
      showLayerControl: false,
      showLayerControl: false,
      showBaseMapControl: false,
      showOpacityControl: false,
      showLegendControl: !this.state.showLegendControl,
    });
  };
  handleShowBaseMapControl = () => {
    this.setState({
      showLayerControl: false,
      showLayerControl: false,
      showLegendControl: false,
      showOpacityControl: false,
      showBaseMapControl: !this.state.showBaseMapControl,
    });
  };
  handleShowOpacityControl = () => {
    this.setState({
      showLayerControl: false,
      showLayerControl: false,
      showLegendControl: false,
      showBaseMapControl: false,
      showOpacityControl: !this.state.showOpacityControl,
    });
  };
  handleShowSettingsControl = () => {
    this.setState({
      showLayerControl: false,
      showLayerControl: false,
      showLegendControl: false,
      showBaseMapControl: false,
      showOpacityControl: false,
      showSettingsControl: !this.state.showSettingsControl,
    });
  };

  render() {
    const { enabled } = this.props;
    
    return (
      <React.Fragment>
        <div id="map" className="map">
          <DefaultExtend />
          <GetCount />
          {this.state.userDetails?.user_type !== 6 && <AddAsset />}
          <SelectByPolygon  
          renderEvent={this.state.renderEvent}
          mapLayers={this.state.layers}
            setZoom={() => this.setZoom()}
          />
          <GoToXY />
          <Buffer />
          <CurrentLocation vectorSource={this.state.vectorSource} />
          <ExportPdf
            renderEvent={this.state.renderEvent}
            mapLayers={this.state.layers}
            setZoom={() => this.setZoom()}
          />
          <MeasureAreaLength
            handleShowMeasurePanelControl={
              this.props.handleShowMeasurePanelControl
            }
          />
          <BaseMap
            showBaseMapControl={this.props.showBaseMapControl}
            baseLayers={this.state.baseLayers}
            handleShowBaseMapControl={this.props.handleShowBaseMapControl}
          />
          <Settings
            showSettingsControl={this.props.showSettingsControl}
            handleShowSettingsControl={this.props.handleShowSettingsControl}
          />
          <LayerTree
            showLayerControl={this.props.showLayerControl}
            mapLayers={this.state.layers}
            handleShowControl={this.props.handleShowLayerControl}
          />
          <Opacity
            showOpacityControl={this.props.showOpacityControl}
            mapLayers={this.state.layers}
            handleShowControl={this.props.handleShowOpacityControl}
          />
          <StyleLoader
            showStyleControl={this.props.showStyleControl}
            mapLayers={this.state.layers}
            handleShowControl={this.props.handleShowStyleControl}
          />
          <LegendWidget
            showLegendControl={this.props.showLegendControl}
            mapLayers={this.state.layers}
            handleShowControl={this.props.handleShowLegendControl}
          />
         
        </div>
        <FeatureInfo
          clickEvent={this.state.clickEvent}
          mapLayers={this.state.layers}
        />
        {this.props.feature_popup &&(
          <SearchLayerPopup 
          showLayerControl={this.props.showLayerControl}
            mapLayers={this.state.layers}
            handleShowControl={this.props.handleShowLayerControl}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  console.log("sttae values",state);
  
  return {
    mapComponent: state.mapReducer.OlMap,
    layers: state.mapReducer.mapLayers,
    enabled: state.mapReducer.widgetEnable,
    feature_popup: state.mapSearch.feature_popup,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateMapReference: (mapComponent) =>
      dispatch(updateMapReference(mapComponent)),
    fetchDataByValue: (searchBy, query) =>
      dispatch(fetchDataByValue(searchBy, query)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OlMap));
